<title> Votaciones | Registro Votantes </title>
<!-- SPINNER -->
<div [ngStyle]="{'display':mostrarCarga}" class="modal" data-keyboard="false" tabindex="-1"
  aria-labelledby="exampleModalLabel">
  <div id="config-spinner">
    <div class="text-center">
      <div class="modal-dialog">
        <div class="spinner-border configSpinner" role="status">
          <span class="visually-hidden modal sr-only"></span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex">
  <!-- -------------- -->
  <!--    SLIDEBAR    -->
  <!-- -------------- -->
  <div style="position: absolute; z-index: 1;">
    <app-navbar></app-navbar>
  </div>
  <div style="z-index:0;" id="contenido" class="w-100">
    <header class="d-flex flex-wrap py-3 mb-2 bg-light posicion-nombreUsuario">
      <svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" fill="currentColor" class="bi bi-person-circle"
        viewBox="0 0 16 16" *ngIf="!identity.foto">
        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
        <path fill-rule="evenodd"
          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
      </svg>
      <div class="avatar_mask" *ngIf="identity.foto">
        <img src="{{identity.foto}}">
      </div>
      <span class="nombre-usuario">{{identity.name}}</span>
    </header>
    <div class="configTituloPricipal">{{page_title}}</div>
    <hr>
    <div class="margen-btnCrear animacionSecundaria crear-user" type="button">
      <span class="texto-crearUsuario">Crear Votante</span>
      <button class="btn btn-crearUsuario" (click)="abrirModalCrear()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-plus iconCrear" viewBox="0 0 16 16">
          <path
            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg>
      </button>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="buscador">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Buscar..</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ingrese el dato a buscar..." #input>
          </mat-form-field>
        </div>
        <div class="table-responsive animacionSecundaria margen-table">
          <table mat-table [dataSource]="user" class="table table-striped">

            <!--Column -->
            <ng-container matColumnDef="No. Documento">
              <mat-header-cell *matHeaderCellDef> No. Documento </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.cedula}} </mat-cell>
            </ng-container>
            <!--Column -->
            <ng-container matColumnDef="Nombre">
              <mat-header-cell *matHeaderCellDef> Nombre </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
            </ng-container>
            <!--Column -->
            <ng-container matColumnDef="Correo Electronico">
              <mat-header-cell *matHeaderCellDef> Correo Electronico </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
            </ng-container>
            <!--Column -->
            <ng-container matColumnDef="Poblacion Convocada">
              <mat-header-cell *matHeaderCellDef> Población </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <h3>
                  <span class="badge badge-info" *ngIf="element.id_rol=='1'">ADMIN</span>
                </h3>
                <h3>
                  <span class="badge badge-info" *ngIf="element.id_rol=='2'">USUARIO EXTERNO</span>
                </h3>
                <h3>
                  <span class="badge badge-success" *ngIf="element.id_rol=='4'">ESTUDIANTE</span>
                </h3>
                <h3>
                  <span class="badge badge-warning" *ngIf="element.id_rol=='5'">DOCENTE</span>
                </h3>
                <h3>
                  <span class="badge badge-primary" *ngIf="element.id_rol=='6'">ADMINISTRATIVO</span>
                </h3>
              </mat-cell>
            </ng-container>
            <!--Column -->
            <ng-container matColumnDef="Facultad">
              <mat-header-cell *matHeaderCellDef> Facultad </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <h3>
                  <h5 style="color: #dc3545; font-weight: 900"  *ngIf="element.id_facultad==null">NO REGISTRADO</h5>
                  <h5  style="color: #007bff; font-weight: 900" *ngIf="element.id_facultad=='INGENIERIA'">{{element.id_facultad}}</h5>
                  <h5  style="color: black; font-weight: 900" *ngIf="element.id_facultad=='VETERINARIA'">{{element.id_facultad}}</h5>
                  <h5 style="color: darkgoldenrod; font-weight: 900" *ngIf="element.id_facultad=='ADMINISTRACION'">{{element.id_facultad}}</h5>
                  <h5 style="color: darkmagenta; font-weight: 900" *ngIf="element.id_facultad=='ADMINISTRATIVOS'">{{element.id_facultad}}</h5>
                </h3>
              </mat-cell>
            </ng-container>
            <!--Column -->
            <ng-container matColumnDef="Candidato">
              <mat-header-cell *matHeaderCellDef> Candidato </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <label class="cont">
                  <div *ngIf="element.candidato==false">
                    <input type="checkbox" name="candidato" value="true" [swal]="estadoSwal">

                  </div>
                  <div *ngIf="element.candidato==null">
                    <input type="checkbox" name="candidato" value="true" [swal]="estadoSwal">

                  </div>
                  <div *ngIf="element.candidato==true">
                    <input type="checkbox" name="candidato" checked value="false" [swal]="estadoSwal"><br>

                  </div>
                </label>
                <swal #estadoSwal title="¿Esta Seguro de cambiar el estado?" text="Esto no se puede deshacer"
                  confirmButtonColor="#0d6efd" icon="question" [showCancelButton]="true" [focusCancel]="true"
                  (confirm)="checkCandidato(element.id,element.candidato)">
                </swal>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Imagen">
              <mat-header-cell *matHeaderCellDef> Imagen </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div class="upload-btn-wrapper" *ngIf="element.candidato==true">
                  <button class="file">Foto</button>
                  <input type="file" accept=".png, .jpg, .jpeg, .gif" name="imagen" alife-file-to-base64
                    (onFileChanged)="imageUpload(element.id, $event)" />
                </div>
              </mat-cell>
            </ng-container>
            <!--Column -->
            <ng-container matColumnDef="Acciones">
              <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
              <mat-cell *matCellDef="let users">

                <button class="btn btn-info btn-foto" (click)="fotoCandidato(users.foto, users.name)"
                  *ngIf="users.candidato==true" title="Ver Foto Candidato">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" fill="currentColor"
                    class="bi bi-person-badge foto" viewBox="0 0 16 16">
                    <path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                      d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z" />
                  </svg>
                </button>
                <button class="btn btn-info btn-foto" *ngIf="users.candidato==false" disabled>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" fill="currentColor"
                    class="bi bi-person-badge foto" viewBox="0 0 16 16">
                    <path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                      d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z" />
                  </svg>
                </button>
                <button class="btn btn-info btn-foto" *ngIf="users.candidato==null" disabled>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" fill="currentColor"
                    class="bi bi-person-badge foto" viewBox="0 0 16 16">
                    <path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                      d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z" />
                  </svg>
                </button>
                <button class="btn btn-secondary btn-actualizar" (click)="getUserid(users.id)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" fill="currentColor"
                    class="btn-editDelete bi bi-pencil-square" viewBox="0 0 16 16">
                    <path
                      d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                  </svg>
                </button>
                <button class="btn btn-danger btn-eliminar" [swal]="deleteSwal">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" fill="currentColor"
                    class="btn-editDelete bi bi-trash" viewBox="0 0 16 16">
                    <path
                      d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                  </svg>
                  <swal #deleteSwal title="¿Esta Seguro de Eliminar a {{ users.name }}?"
                    text="Esto no se puede deshacer" icon="question" [showCancelButton]="true" [focusCancel]="true"
                    confirmButtonColor="#0d6efd" confirmButtonColor="#0d6efd" (confirm)="deleteUser(users.id)">
                  </swal>
                </button>

              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of user"></mat-paginator>
        </div>

      </div>
      <div class="row">
        <div class="col-md-5"></div>
        <div class="col-md-2">
          <button id="btnimportar" type="button" class="btn btn-warning mt-2" (click)="importar()">Importar <i class="bi bi-upload"></i></button>
        </div>
        <div class="col-md-5"></div>
      </div>
    </div>
    <!--          Modal Crear          -->
    <!-- ---------------------------------- -->
    <div id="{{'myModalVotante'}}" class="modal" [ngStyle]="{'display':modalCrear}">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <h5 class="tituloModal">Crear Votante</h5>
          <hr>
          <div class="modal-body">
            <form class="container" #formCrear=ngForm>

              <div class="mb-3">
                <label class="form-label">Nombre Completo <b style="color: rgb(247, 89, 89);">*</b></label>
                <input class="form-control" type="text" name="name" required placeholder="Digite el nombre completo"
                  #name="ngModel" [(ngModel)]="userCrear.name"
                  oninput="javascript:this.value=this.value.toUpperCase();">
                <small *ngIf="!name.valid && name.touched" class="invalid-feedback d-block">El Nombre no Existe</small>
              </div>

              <div class="mb-3">
                <label class="form-label">Número de Cédula <b style="color: rgb(247, 89, 89);">*</b></label>
                <input class="form-control" type="text" name="cedula" required placeholder="Digite el número de cédula"
                  #cedula="ngModel" [(ngModel)]="userCrear.cedula"
                  oninput="javascript:this.value=this.value.toUpperCase();">
                <small *ngIf="!cedula.valid && cedula.touched" class="invalid-feedback d-block">El número de cédula no
                  Existe</small>
              </div>
              <div class="mb-3">
                <label class="form-label">Correo Electrónico <b style="color: rgb(247, 89, 89);">*</b></label>
                <input class="form-control" type="email" name="email" required
                  placeholder="Digite el correo electrónico" #email="ngModel" [(ngModel)]="userCrear.email">
                <small *ngIf="!email.valid && email.touched" class="invalid-feedback d-block">El email no Existe</small>
              </div>
              <div class="mb-3">
                <label class="form-label">Población <b style="color: rgb(247, 89, 89);">*</b></label>
                <select class="form-control  mt-2" name="id_rol" #id_rol="ngModel" [(ngModel)]="userCrear.id_rol">
                  <option selected hidden value="null">Selecciona</option>
                  <option class="form__option" *ngFor="let role of roles" value="{{role.id}}">
                    {{role.nombre}}
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">Organo <b style="color: rgb(247, 89, 89);">*</b></label>
                <select class="form-control  mt-2" name="id_facultad" #id_facultad="ngModel"
                  [(ngModel)]="userCrear.id_facultad">
                  <option selected hidden value="null">Selecciona</option>
                  <option class="form__option" *ngFor="let facultad of facultades" value="{{facultad.id}}">
                    {{facultad.nombre_facultad}}
                </select>
              </div>
              <div class="text-center">
                <button class="btn btn-primary btnModal col-lg-4 col-sm-5" (click)="crearUser(formCrear)"
                  [disabled]="formCrear.invalid" data-dismiss="modal">Crear</button>
                <button type="button" class="btn btn-danger btnModal col-lg-4 col-sm-5" data-dismiss="modal"
                  (click)="cancelarModalCrear(formCrear)">Cancelar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!--          Modal Actualizar          -->
    <!-- ---------------------------------- -->
    <div [ngStyle]="{'display':modalActualizar}" id="{{'myModalUpdate'}}" class="modal">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <h5 class="tituloModal">Actualizar Votante</h5>
          <hr>
          <div class="modal-body">
            <form class="container" #formupdate=ngForm>
              <div class="mb-3">
                <label class="form-label">Nombre Completo <b style="color: rgb(247, 89, 89);">*</b></label>
                <input class="form-control" type="text" name="name" required placeholder="Digite el nombre completo"
                  #name="ngModel" [(ngModel)]="userEdit.name" oninput="javascript:this.value=this.value.toUpperCase();">
                <small *ngIf="!name.valid && name.touched" class="invalid-feedback d-block">El Nombre no Existe</small>
              </div>

              <div class="mb-3">
                <label class="form-label">Número de Cédula <b style="color: rgb(247, 89, 89);">*</b></label>
                <input class="form-control" type="text" name="cedula" required placeholder="Digite el número de cédula"
                  #cedula="ngModel" [(ngModel)]="userEdit.cedula"
                  oninput="javascript:this.value=this.value.toUpperCase();">
                <small *ngIf="!cedula.valid && cedula.touched" class="invalid-feedback d-block">El número de cédula no
                  Existe</small>
              </div>
              <div class="mb-3">
                <label class="form-label">Correo Electrónico <b style="color: rgb(247, 89, 89);">*</b></label>
                <input class="form-control" type="email" name="email" required
                  placeholder="Digite el correo electrónico" #email="ngModel" [(ngModel)]="userEdit.email">
                <small *ngIf="!email.valid && email.touched" class="invalid-feedback d-block">El email no Existe</small>
              </div>
              <div class="mb-3">
                <label class="form-label">Población <b style="color: rgb(247, 89, 89);">*</b></label>
                <select class="form-control  mt-2" name="id_rol" #id_rol="ngModel" [(ngModel)]="userEdit.id_rol">
                  <option selected hidden value="null">Selecciona</option>
                  <option class="form__option" *ngFor="let role of roles" value="{{role.id}}">
                    {{role.nombre}}
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">Organo <b style="color: rgb(247, 89, 89);">*</b></label>
                <select class="form-control  mt-2" name="id_facultad" #id_facultad="ngModel"
                  [(ngModel)]="userEdit.id_facultad">
                  <option selected hidden value="null">Selecciona</option>
                  <option class="form__option" *ngFor="let facultad of facultades" value="{{facultad.id}}">
                    {{facultad.nombre_facultad}}
                </select>
              </div>
              <div class="text-center">
                <button class="btn btn-primary btnModal col-lg-4 col-sm-5" [swal]="updateSwal">Guardar</button>
                <swal #updateSwal title="Estas seguro de Actualizar" text="Esto no se puede deshacer" icon="warning"
                  [showCancelButton]="true" confirmButtonColor="#0d6efd" [focusCancel]="true"
                  (confirm)="onSubmitUpdate(formupdate)">
                </swal>
                <button type="button" class="btn btn-danger btnModal col-lg-4 col-sm-5"
                  (click)="modalEditCancelar()">Cancelar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <div class="position-sticky animacionSecundaria" style="top: 120vh;">
      <footer>
        <div class="container text-center p-3">
          <blockquote class="blockquote" style="font-size: 18px; color: black; font-weight: 400; margin-bottom: 0%;">
            Copyright © 2022 · <a href="/desarrolladores" style="text-decoration: none; color: black"
              target="_blank">Gestión TIC e Innovación Tecnológica</a> | <a href="https://www.corhuila.edu.co"
              style="text-decoration: none; color: black;" target="_blank">Corporación Universitaria del Huila -
              CORHUILA</a>
          </blockquote>
        </div>
      </footer>
    </div>
  </div>
</div>
