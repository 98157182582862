import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/service/user.service';
import Swal from 'sweetalert2';
import { IdentityGuard } from 'src/app/service/identity.guard';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css'],
  providers: [
    UserService,
    IdentityGuard
  ]
})
export class UsuariosComponent implements OnInit {

  public page_title: string;
  //public user: any;
  public status: string;
  public identity: any;
  displayedColumns: string[] = ['#', 'No. Documento', 'Nombre', 'Correo Electronico', "Rol","Candidato", "Acciones"];
  user = new MatTableDataSource(Array(User));
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  public mostrarCarga: any;

  constructor(
    private _userService: UserService,
    private _validarToken: IdentityGuard
  ) {
    this.page_title = "USUARIOS";
    this.status = '';
    this.identity = this._userService.getIdentity();
    this.mostrarCarga = "block";
  }

  ngOnInit(): void {
    this.getUsers();
  }

  ngAfterViewInit() {
    this.user.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.user.filter = filterValue.trim().toLowerCase();
  }

  // obtener usuarios
  getUsers() {
    this._userService.getUsers(1).subscribe(
      (response: any) => {
        if (response.status == 'Token is Expired') {
          this._validarToken.validarToken();
        }else if (response.respuesta == "OK") {
          this.status = response.respuesta;

          let result = response.data.map(function (obj: any) {
            return new User(obj.id, obj.cedula, obj.name, obj.email, obj.password, obj.token, obj.id_rol, obj.id_tipo_cargo, obj.ingreso, obj.candidato, obj.id_estado, obj.foto, obj.id_facultad);

          });
          this.user = new MatTableDataSource(result);
          this.user.paginator = this.paginator;
          this.mostrarCarga = "none";
        } else {
          this.mostrarCarga = "none";
          this.status = "error";
        }
      }, error => {
        this.status = "error";
      });
  }

  // eliminar usuario id
  deleteUser(id: any) {
    this._userService.deleteUser(id).subscribe(
      (response: any) => {

        if (response.respuesta == "OK") {
          this.status = "success";
          // alerta
          this.alert('success', 'Oops...', 'Usuario Eliminado!');
          // volver a consultar el metodo para obtener los usuarios
          this.getUsers();
        } else {
          this.status = "error";
          this.alert('error', 'Oops...', 'No se elimino el Usuario!');
        }
      }, error => {
        this.alert('error', 'Oops...', 'Error de Conexión');
      }
    );
  }
  // mensaje de alerta
  alert(icon: any, title: any, message: any) {
    Swal.fire({
      position: 'center',
      icon: icon,
      title: title,
      text: message,
      showCloseButton: false,
      timer: 2000
    });
  }


}
