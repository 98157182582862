import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { global } from "./global";

@Injectable()
export class UserService {
  public url: string;
  public identity: any;
  public token: any;

  constructor(private _http: HttpClient) {
    this.url = global.url;
  }

  public headers = new HttpHeaders(
    {
      Authorization: `Bearer ${sessionStorage.getItem('tokenVotacionUH')}`,
    }
  );

  // metodo para iniciar sesión
  signup(user: any): Observable<{status: number, message: string}> {
    return this._http.post<{status: number, message: string}>(this.url + 'login', user);
  }
  // obtener todos los usuarios
  getUsers(opcion: any): Observable<any> {
    return this._http.get(this.url + 'registrarUsuario?opcion=' + opcion, { headers: this.headers });
  }
  // obtener usuario por id
  getUserId(id: any): Observable<any> {
    var id_user = id;
    return this._http.get(this.url + 'registrarUsuario/' + id_user, { headers: this.headers });
  }
  // registrar usuarios
  register(user: any): Observable<any> {
    return this._http.post(this.url + 'registrarUsuario', user, { headers: this.headers });
  }
  // actualizar usuario por id
  updateUserId(id: any, data: any): Observable<any> {
    return this._http.put(this.url + "registrarUsuario/" + id, data, { headers: this.headers });
  }
  // eliminar user por id
  deleteUser(id: any): Observable<any> {
    var id_user = id;
    return this._http.delete(this.url + 'registrarUsuario/' + id_user, { headers: this.headers });
  }
  // listar roles
  getRoles(): Observable<any> {
    return this._http.get(this.url + 'roles/', { headers: this.headers });
  }
  // restablecer clave
  restablecimiento(data: any) {
    return this._http.post(this.url + "enviarRestablecimiento/", data);
  }

  getIdentity() {
    const identity = JSON.parse(sessionStorage.getItem('name')! ?? null);
    if( identity != null ) {
      return identity.id_rol;
    }
  }

  getUser(){
    const user = JSON.parse(sessionStorage.getItem('name')! ?? null);
    return user;
  }

  getToken() {
    let token = sessionStorage.getItem("tokenVotacionUH");
    if (token != null && token != undefined && token != "undefined") {
      this.token = token;
    } else {
      this.token = null;
    }
    return this.token;
  }

  // cambiar estado Candidato a Usuario
  changeCandidato(id: any, candidato: any): Observable<any> {
    return this._http.post(this.url + "marcarCandidato", { id_usuario: id, candidato: candidato }, { headers: this.headers });
  }
  // subir imagen candidato
  image(id: any, image: any): Observable<any> {
    return this._http.post(this.url + "subirFoto", { id: id, foto: image }, { headers: this.headers });
  }
  // obtener facultad
  getFacultades(): Observable<any> {
    return this._http.get(this.url + "facultades", { headers: this.headers });
  }
  // importar archivo csv para registro votantes masivo
  import(data: any): Observable<any> {
    return this._http.post(this.url + 'importarUsuarios', data, { headers: this.headers });
  }
  enviarAcceso(data: any): Observable<any> {
    return this._http.post(this.url + 'usuario/enviar_acceso', { nombre_tarjeton: data.nombre_tarjeton, id_rol: data.id_rol, id_dirigido: data.id_dirigido }, { headers: this.headers });
  }
}
