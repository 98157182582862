import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { Role } from 'src/app/models/role';
import { UserService } from 'src/app/service/user.service';
import { FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { IdentityGuard } from 'src/app/service/identity.guard';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  providers: [
    UserService,
    IdentityGuard
  ],
})
export class RegisterComponent implements OnInit {


  public page_title: string;
  public user: User;
  public role: any;
  public status: string;
  public patronEmail: any;
  public identity: any;

  constructor( private _userservice: UserService,
    private _validarToken: IdentityGuard,
    private _router: Router) {
    this.page_title = "REGISTRAR";
    this.user = new User(1,'','','','','',null,'','',false,'','','');
    this.role = Array(Role);
    this.status = "";
    this.patronEmail =/\w+@corhuila.edu.co$/;
    this.identity = this._userservice.getIdentity();
  }

  ngOnInit(): void {
    this.getRoles();
  }

  onSubmit(form: any){
    const cedula = new FormControl(form.value.txtCedula, [Validators.maxLength(13)]);
    const nombre = new FormControl(form.value.name, [Validators.maxLength(50)]);
    const email = new FormControl(form.value.txtCorreo, [Validators.maxLength(50), Validators.pattern(this.patronEmail)]);
    const password = new FormControl(form.value.txtContrasena, [Validators.maxLength(30), Validators.minLength(8)]);
    const role = new FormControl(form.value.id_rol, [Validators.required]);

    if(cedula.invalid){
      this.validatorCedula(cedula);
    }
    else if(nombre.invalid){
      this.validatorCedula(nombre);
    }
    else if(email.invalid){
      this.validatorEmail(email);
    }
    else if(password.invalid){
      this.validatorPassword(password);
    }
    else if(role.invalid){
      this.validatorRole(role);
    }else{
      this._userservice.register(this.user).subscribe(
        (response: any)=>{
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.mensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this._router.navigate(['usuarios']);
        }, error =>{
          console.log(error);
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'No se pudo crear el Usuario!',
            showConfirmButton: false,
            timer: 1500
          });
        }
      );
    }
  }

  validatorCedula(dato: any){
    if (dato.errors?.maxlength) {
      this.mostrarAlerta('El campo número de cédula no puede tener más de 13 caracteres');
    }
  }
  validatorNombre(dato: any){
    if (dato.errors?.maxlength) {
      this.mostrarAlerta('El campo número de cédula no puede tener más de 50 caracteres');
    }
  }
  validatorEmail(data: any){
    if (data.errors?.maxlength) {
      this.mostrarAlerta('El campo correo electrónico no puede tener más de 50 números');
    } else if (data.errors?.pattern) {
      this.mostrarAlerta('El formato del correo electrónico no es válido, debe ser el institucional @corhuila.edu.co');
    }
  }
  validatorPassword(data: any){
    if (data.errors?.maxlength) {
      this.mostrarAlerta('El campo contraseña no puede tener más de 30 caracteres');

    } else if (data.errors?.minlength) {
      this.mostrarAlerta('El campo contraseña debe tener como mínimo 8 caracteres');
    }
  }
  validatorRole(data: any){
    if (data.errors?.required) {
      this.mostrarAlerta('Debe seleccionar un rol');
    }
  }

  //Alerta de Validaciones creacion usuarios
  mostrarAlerta(texto: String) {
    Swal.fire({
      icon: 'warning',
      title: '¡Atención!',
      text: '' + texto,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#FF4D4D',
      allowOutsideClick: false

    })
  }


  //obtener roles
  getRoles(){
    this._userservice.getRoles().subscribe(
      (response: any)=>{
        if(response.status =='Token is Expired'){
          this._validarToken.validarToken();
        }else if(response.respuesta=='OK'){
          this.role= response.data;
          this.status="success";
        }else{
          this.status="error";
        }
      }, error =>{
        this.status="error";
      }
    );
  }

}
