import { Component, OnInit } from '@angular/core';
import { Role } from 'src/app/models/role';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/service/user.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { FormControl, Validators } from '@angular/forms';
import { IdentityGuard } from 'src/app/service/identity.guard';

@Component({
  selector: 'app-editar-usuario',
  templateUrl: './editar-usuario.component.html',
  styleUrls: ['./editar-usuario.component.css'],
  providers: [
    UserService,
    IdentityGuard
  ]
})
export class EditarUsuarioComponent implements OnInit {

  public user: any;
  public page_title: string;
  public role: any;
  public status: string;
  public patronEmail: any;
  public identity: any;


  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _userService: UserService,
    private _validarToken: IdentityGuard)
    {
    this.user = Array(User);
    this.page_title = "EDITAR USUARIO"
    this.role = Array(Role);
    this.status="";
    this.patronEmail =/\w+@corhuila.edu.co$/;
    this.identity = this._userService.getIdentity();
  }

  ngOnInit(): void {
    this.getRoles();
    this.getUser();
  }
  // obtener el usuario que se va a actualizar
  getUser(){
    this._route.params.subscribe(
      params=>{
        let id_params = +params["id"];
        this._userService.getUserId(id_params).subscribe(
          (response: any)=>{
            if(response.status =='Token is Expired'){
              this._validarToken.validarToken();
            }
            else if(response.respuesta=='OK'){
              this.user = response.data;
              this.status = "success";
              this.mostrarAlerta(this.status,'Usuario '+ this.user.name+' Cargado');
            }else{
              this.status = "error";
              this.mostrarAlerta("warning",'Error al Cargar el Usuario');
            }
          }, error =>{
            this.status = "error";
              this.mostrarAlerta("warning",'Error al Cargar el Usuario');
          }
        );
      }
    );
  }
  // cambio value del rol
  setValorSelectRol(value: any){
    this.user.rol_id = value;
    console.log(this.user.rol_id);
  }
  // guardar los datos que estan en el formulario de editar
  onSubmit(form: any){
    const cedula = new FormControl(form.value.txtCedula, [Validators.maxLength(13)]);
    const nombre = new FormControl(form.value.name, [Validators.maxLength(50)]);
    const email = new FormControl(form.value.txtCorreo, [Validators.maxLength(50), Validators.pattern(this.patronEmail)]);
    const password = new FormControl(form.value.txtContrasena, [Validators.maxLength(30), Validators.minLength(8)]);
    const role = new FormControl(form.value.id_rol, [Validators.required]);
    if(cedula.invalid){
      this.validatorCedula(cedula);
    }
    else if(nombre.invalid){
      this.validatorCedula(nombre);
    }
    else if(email.invalid){
      this.validatorEmail(email);
    }
    else if(password.invalid){
      this.validatorPassword(password);
    }
    else if(role.invalid){
      this.validatorRole(role);
    }else{
      console.log(this.user.id_rol);
      this._userService.updateUserId(this.user.id, this.user).subscribe(
        (response: any)=>{
          if(response.respuesta=='OK'){
            this.status ="success";
            this.mostrarAlerta("success",'Usuario Actualizado Exitosamente');
            this._router.navigate(['usuarios']);
          }else{
            this.status="error";
            this.mostrarAlerta("warning",'No se pudo actualizar el usuario');
          }
        }, error =>{
          this.status="error";
          this.mostrarAlerta(this.status,error);
        }
      );
    }
  }
  validatorCedula(dato: any){
    if (dato.errors?.maxlength) {
      this.mostrarAlerta('warning','El campo número de cédula no puede tener más de 13 caracteres');
    }
  }
  validatorNombre(dato: any){
    if (dato.errors?.maxlength) {
      this.mostrarAlerta('warning','El campo número de cédula no puede tener más de 50 caracteres');
    }
  }
  validatorEmail(data: any){
    if (data.errors?.maxlength) {
      this.mostrarAlerta('warning','El campo correo electrónico no puede tener más de 50 números');
    } else if (data.errors?.pattern) {
      this.mostrarAlerta('warning','El formato del correo electrónico no es válido, debe ser el institucional @corhuila.edu.co');
    }
  }
  validatorPassword(data: any){
    if (data.errors?.maxlength) {
      this.mostrarAlerta('warning','El campo contraseña no puede tener más de 30 caracteres');

    } else if (data.errors?.minlength) {
      this.mostrarAlerta('warning','El campo contraseña debe tener como mínimo 8 caracteres');
    }
  }
  validatorRole(data: any){
    if (data.errors?.required) {
      this.mostrarAlerta('warning','Debe seleccionar un rol');
    }
  }
  //Alerta de Validaciones creacion usuarios
  mostrarAlerta(status: any,texto: String) {
    Swal.fire({
      position: 'center',
      icon: status,
      title: 'Atención',
      text: '' + texto,
      showConfirmButton: false,
      timer: 1500
    });
  }
  //obtener roles
  getRoles(){
    this._userService.getRoles().subscribe(
      (response: any)=>{
        if(response.respuesta=='OK'){
          this.role= response.data;
          this.status="success";
        }
      }, error =>{
        this.status="error";
      }
    );
  }

}
