import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';
import { TarjetonService } from 'src/app/service/tarjeton.service';
import { Tarjeton } from 'src/app/models/tarjetone';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/service/user.service';
import { Plancha } from 'src/app/models/plancha';
import { IdentityGuard } from 'src/app/service/identity.guard';
import { Role } from 'src/app/models/role';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { User } from 'src/app/models/user';
import { PlanchaService } from 'src/app/service/plancha.service';
import { Tipo_cargo } from 'src/app/models/tipo_cargo';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-tarjetones',
  templateUrl: './tarjetones.component.html',
  styleUrls: ['./tarjetones.component.css'],
  providers: [
    TarjetonService,
    UserService,
    IdentityGuard,
    PlanchaService
  ]
})
export class TarjetonesComponent implements OnInit {

  isChecked = true;

  public page_title: string;
  public token: any;
  public identity: any;

  public tarjetonId: any;
  public modalActualizar: any;
  public status: any;
  public title: any;
  public plancha: any;
  public modalPlancha: any;
  public dataTarjeton: any;
  public roles: any;
  public tipo_cargos: any;
  public estados: any;
  public modalCrear: any;
  public mostrarCarga: any;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  public users: any;
  public users_aux: any;

  // table tarjetones
  public tarjeton: any;
  tarj = new MatTableDataSource(Array(Tarjeton));
  displayedColumns: string[] = ['Tarjetón', 'Fecha Inicio', 'Fecha Cierre', 'Población Convocada', 'Organo Elegido', "Estado", "Acciones"];

  // table planchas
  listPlancha = new MatTableDataSource(Array(Plancha));
  displayedColumnsPlancha: string[] = ['Plancha', 'Candidato P', "Candidato S", "Estado", "Acciones"];
  //
  public modalActualizarPlancha: any;
  public planchaId: any;
  public listTarjeton: any;
  //

  constructor(private _tarjetonService: TarjetonService,
    private _userService: UserService,
    private _validarToken: IdentityGuard,
    private _planchaService: PlanchaService) {
    this.page_title = "TARJETONES";
    this.token = this._userService.getToken();
    this.identity = this._userService.getIdentity();
    this.tarjeton = new Tarjeton('', '', '', '', '', '', '', '', '', '');
    this.plancha = new Plancha('', '', '', '', '', '');
    this.tarjetonId = Array(Tarjeton);
    this.modalActualizar = "none";
    this.status = "";
    this.title = "";
    this.modalPlancha = "none";
    this.dataTarjeton = Array(Tarjeton);
    this.roles = Array(Role);
    this.users = Array(User);
    this.users_aux = Array(User);
    this.modalActualizarPlancha = "none";
    this.modalCrear = "none";
    this.planchaId = Array(Plancha);
    this.listTarjeton = Array(Tarjeton);
    this.tipo_cargos = Array(Tipo_cargo);
    this.estados =
      [
        { id: 1, nombre_estado: 'Activo', fecha_creacion: '', fecha_actualizacion: '' },
        { id: 2, nombre_estado: 'Inactivo', fecha_creacion: '', fecha_actualizacion: '' }
      ]
    this.mostrarCarga = "block";

  }


  ngAfterViewInit() {
    this.tarj.paginator = this.paginator;
  }

  ngOnInit(): void {
    // obtener todos los  tarjetones creados
    this.getTarjetones();
    // obtener usuarios candidatos para las planchas
    this.getUsers();
    // obtener roles
    this.getRoles();
    // obtener tipos de cargos para crear tarjeton
    this.getTipoCargos();
  }

  // Obtener usuarios tipo Candidatos
  getUsers() {
    this._userService.getUsers(3).subscribe(
      (response: any) => {
        this.users = response.data;
        this.users_aux = response.data;
      }, error => {
      }
    );
  }
  // tabla tarjeton
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tarj.filter = filterValue.trim().toLowerCase();
  }
  // tabla plancha
  applyFilterPlancha(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.listPlancha.filter = filterValue.trim().toLowerCase();
  }
  // obtener tipos cargos
  getTipoCargos() {
    this._tarjetonService.getCargos().subscribe(
      response => {
        this.tipo_cargos = response;
      }, error => {

      }
    );
  }
  // obtener roles
  getRoles() {
    this._userService.getRoles().subscribe(
      (response: any) => {
        // mostar los roles menos el 1 que es de Administrador
        this.roles = response.data.filter((b: any) => {
          return (b.id != '1' && b.id != '2')
        });
      }, error => {
      }
    );
  }
  // modal crear abrir
  modalAbrirCrear() {
    this.modalCrear = "block";
  }
  // cancelar modal crear tarjeton
  cancelarCrear(form: any) {
    this.modalCrear = "none";
    form.reset();
  }
  // crear tarjetones
  crearTarjeton(form: any) {

    const nombre_tarjeton = new FormControl(form.value.nombre_tarjeton, [Validators.required, Validators.maxLength(45)]);
    const fecha_inicio = new FormControl(form.value.fecha_inicio, [Validators.required]);
    const fecha_cierre = new FormControl(form.value.fecha_cierre, [Validators.required]);
    const role = new FormControl(form.value.id_rol, [Validators.required]);
    const dirigido = new FormControl(form.value.id_dirigido, [Validators.required]);

    if (nombre_tarjeton.invalid) {
      this.mensaje('warning', 'Opss...', 'Por favor, Ingresa el nombre del Tarjetón');
    } else if (fecha_inicio.invalid) {
      this.mensaje('warning', 'Opss...', 'Por favor, Ingresa la fecha de inicio');
    } else if (fecha_cierre.invalid) {
      this.mensaje('warning', 'Opss...', 'Por favor, Ingresa la fecha de cierre');
    } else if (role.invalid) {
      this.mensaje('warning', 'Opss...', 'Por favor, Selecciona un rol');
    } else if (dirigido.invalid) {
      this.mensaje('warning', 'Opss...', 'Por favor, Selecciona a quien va dirigido');
    } else {
      this._tarjetonService.registerTarjeton(this.tarjeton).subscribe(
        response => {
          console.log(response);
          this.status = "success";
          this.title = "Se ha Creado el Tarjetón " + this.tarjeton.nombre_tarjeton;
          this.mensaje(this.status, 'Creación', this.title);
          form.reset();
          this.getTarjetones();
          this.modalCrear = "none";
        }, error => {
          this.mensaje('error', 'Opss...', 'No se pudo crear el tarjetón');
        }
      );
    }
  }
  // obtener Tarjetones
  getTarjetones() {
    this._tarjetonService.getTarjetones().subscribe(
      response => {
        if (response.status == 'Token is Expired') {
          this._validarToken.validarToken();
        }
        else if (response.length > 0) {
          this.status = "success";
          // guardar los datos del tarjeton para el modal de actualizar plancha
          this.listTarjeton = response;
          let result = response.map(function (obj: any) {
            return new Tarjeton(obj.id, obj.nombre_tarjeton, obj.fecha_inicio, obj.fecha_cierre, obj.id_user, obj.id_estado, obj.id_dirigido, obj.id_rol, obj.id_rol_label, obj.cargo);
          });
          this.tarj = new MatTableDataSource(result);
          this.tarj.paginator = this.paginator;
          this.mostrarCarga = "none";
        } else {
          this.status = "error";
          this.mostrarCarga = "none";
        }
      }, error => {
        this.status="error";
      }
    );
  }
  // eliminar Tarjeton
  deleteTarjeton(id: any) {
    this._tarjetonService.deleteTarjeton(id).subscribe(
      response => {
        this.status = "success";
        this.title = "Se ha Eliminado el Tarjetón";
        this.mensaje(this.status, 'Eliminación', this.title);
        this.getTarjetones();
      }, error => {
        this.mensaje('error', 'Opss...', 'No se pudo eliminar el Tarjetón');
      }
    );
  }
  // Obtener tarjeton por id
  getTarjeton(id: any) {
    this._tarjetonService.getTarjeton(id).subscribe(
      response => {
        this.tarjetonId = response.data;
        this.modalActualizar = "block";
      }, error => {
      }
    );
  }
  // guardar datos del modal
  Update(form: any) {
    this._tarjetonService.updateTarjeton(this.tarjetonId.id, this.tarjetonId).subscribe(
      response => {
        this.status = "success";
        this.title = "Se ha Actualizado el Tarjetón " + this.tarjetonId.nombre_tarjeton;
        this.mensaje(this.status, 'Actualizacoón', this.title);
        this.getTarjetones();
        this.modalActualizar = "none";
      }, error => {
        this.mensaje('error', 'Opss...', 'No se pudo actualizar el Tarjetón');
      }
    );
  }
  // boton cancelar el modal
  modalCancelar() {
    this.modalActualizar = "none";
  }
  // modal de crear Planca
  modalCrearPlancha(data: Tarjeton) {
    this.dataTarjeton = data;
    this.plancha = new Plancha('', '', this.dataTarjeton.id, '', null, null);
    // obtener las planchas creadas por el tarjeton
    this.getPlancha(data.id);

  }
  // crear plancha
  crearPlancha(form: any) {

    const nombre_plancha = new FormControl(form.value.nombre_plancha, [Validators.required, Validators.maxLength(50)]);
    const select_candidato_principal = new FormControl(form.value.id_principal, [Validators.required]);
    const select_candidato_secundario = new FormControl(form.value.id_secundario, [Validators.required]);

    if (nombre_plancha.invalid) {
      this.mensaje('error', 'Opss...', "Por favor, ingresar el nombre correctamente");
    } else if (select_candidato_principal.invalid) {
      this.mensaje('error', 'Opss...', "Por favor, selecciona un candidato principal");
    } else if (select_candidato_secundario.invalid) {
      this.mensaje('error', 'Opss...', "Por favor, selecciona un candidato secundario");
    } else {
      this._planchaService.add(this.plancha).subscribe(
        response => {
          this.plancha.nombre_plancha = null;
          this.plancha.id_principal = null;
          this.plancha.id_secundario = null;
          this.mensaje('success', '', 'Plancha Creada Correctamente');
          this.getPlancha(response.data.id_tarjeton);
        }, error => {
          this.mensaje('error', 'Opss...', 'No se pudo crear la plancha');
        }
      );
    }
  }
  //
  getPlancha(id: any) {
    // consultar las planchas con el id del tarjeton
    this._planchaService.getPlanchaTarjeton(id).subscribe(
      response => {
        if (response.status == 'Token is Expired') {
          this._validarToken.validarToken();
        }else{
          let result = response.data.map(function (obj: any) {
            return new Plancha(obj.id, obj.nombre_plancha, obj.id_tarjeton, obj.id_estado, obj.principal, obj.suplente);
          });
          this.listPlancha = new MatTableDataSource(result);
          // mostrar modal crear plancha
          this.modalPlancha = "block";
        }
      }, error => {

      }
    );
  }
  // cancelar modal plancha
  cancelarModalPlancha(form: any) {
    this.modalPlancha = "none";
    form.reset();
  }
  // obtener plancha por id
  getPlanchaId(id: any) {
    this._planchaService.getId(id).subscribe(
      response => {
        if (response.status == 'Token is Expired') {
          this._validarToken.validarToken();
        }
        else if (response.respuesta == 'OK') {
          this.planchaId = response.data;
          this.modalActualizarPlancha = "block";
        }

      }, error => {
      }
    );
  }
  // cancelar modal actualizar tarjeton
  cancelarModalUpdatePlancha() {
    this.modalActualizarPlancha = "none";
  }
  // actualizar plancha
  updatePlancha(form: any) {
    this._planchaService.update(this.planchaId.id, this.planchaId).subscribe(
      response => {
        this.getPlancha(this.planchaId.id_tarjeton);
        this.modalActualizarPlancha = "none";
        this.mensaje('success', '', 'Plancha Actualizada Correctamente');
      }, error => {

      }
    );
  }
  // select de candidato principal
  changeCandidato(e: any) {
    this.users_aux = this.users.filter((b: any) => {
      return (b.id != e.target.value)
    });
  }
  // check estado
  checkEstado(e: any) {
    console.log(e);
  }
  convocar(tarjeton: any) {
    this.mostrarCarga="block";
    this._userService.enviarAcceso(tarjeton).subscribe(
      response => {
          this.mostrarCarga="none";
          this.mensaje('success', 'Convocatoria', 'Se Enviaron los Correos Correctamente');
      }
    );
  }
  resultados(id_tarjeton: any){
    this.mostrarCarga="block";
    this._tarjetonService.resultados(id_tarjeton).subscribe(
      response =>{
        this.mostrarCarga="none";
        this.mensaje('success', 'Resultados', 'Los resultados se enviaron');
      }
    );
  }
  // alerta
  mensaje(status: any, title: any, text: any) {
    Swal.fire({
      position: 'center',
      icon: status,
      title: title,
      text: text,
      showConfirmButton: true,
      timerProgressBar: true,
      timer: 4000
    });
  }

}
