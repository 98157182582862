export class Role{
  constructor(
    public id:string,
    public nombre: string,
    public fecha_creacion: string,
    public fecha_actualizacion: string
  ){

  }
}
