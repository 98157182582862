import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class IdentityVotanteGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _userService: UserService,
  ) {
  }

  canActivate() {
    let id_rol = this._userService.getIdentity();
    if (id_rol === 4) {
      return true;
    } else {
      sessionStorage.removeItem('name');
      sessionStorage.removeItem('tokenVotacionUH');
      this._router.navigate(['']);
      return false;
    }
  }

}
