<title> Votaciones | Registro </title>
<!-- SPINNER -->
<div class="modal modal-backdrop" data-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel">
  <div id="config-spinner">
    <div class="text-center">
      <div class="modal-dialog">
        <div class="spinner-border configSpinner" role="status">
          <span class="visually-hidden modal-backdrop sr-only"></span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex">
  <!-- -------------- -->
  <!--    SLIDEBAR    -->
  <!-- -------------- -->
  <div style="position: absolute; z-index: 1;">
    <app-navbar></app-navbar>
  </div>
  <div style="z-index:0;" id="contenido" class="w-100">
    <header class="d-flex flex-wrap py-3 mb-2 bg-light posicion-nombreUsuario">
      <svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" fill="currentColor" class="bi bi-person-circle"
        viewBox="0 0 16 16" *ngIf="!identity.foto">
        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
        <path fill-rule="evenodd"
          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
      </svg>
      <div class="avatar_mask" *ngIf="identity.foto">
        <img src="{{identity.foto}}">
      </div>
      <span class="nombre-usuario">{{identity.name}}</span>
    </header>
    <div class="configTituloPricipal">{{page_title}}</div>
    <div class="margen-btnCrear animacionSecundaria crear-user" type="button">

    </div>
    <div class="table-responsive animacionSecundaria margen-table">
      <div class="modal-body">
        <form class="centrarCampos col-md-8" #registerForm="ngForm" (ngSubmit)="onSubmit(registerForm)">

          <div class="mb-3">
            <label class="form-label">Número de Documento <b style="color: rgb(247, 89, 89);">*</b></label>
            <input class="form-control" type="text" name="txtCedula" required=""
              placeholder="Digite el número de cédula" #cedula="ngModel" [(ngModel)]="user.cedula">
            <small *ngIf="!cedula.valid && cedula.touched" class="invalid-feedback d-block">El Número de Documento no
              Existe</small>
          </div>

          <div class="mb-3">
            <label class="form-label">Nombre Completo <b style="color: rgb(247, 89, 89);">*</b></label>
            <input class="form-control" type="text" name="name" required="required"
              placeholder="Digite el nombre completo" #nombres="ngModel" [(ngModel)]="user.name" required
              pattern="[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,48}"
              oninput="javascript:this.value=this.value.toUpperCase();">
            <small *ngIf="!nombres.valid && nombres.touched" class="invalid-feedback d-block">El Nombre no
              Existe</small>
          </div>

          <div class="mb-3">
            <label class="form-label">Correo Electrónico <b style="color: rgb(247, 89, 89);">*</b></label>
            <input class="form-control" type="email" name="txtCorreo" required=""
              placeholder="Digite el correo electrónico" #email="ngModel" [(ngModel)]="user.email" required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
            <small *ngIf="!email.valid && email.touched" class="invalid-feedback d-block">El Correo no existe</small>
          </div>

          <div class="mb-3">
            <label class="form-label">Rol <b style="color: rgb(247, 89, 89);">*</b></label>
            <select class="form-control" name="id_rol" #id_rol="ngModel" [(ngModel)]="user.id_rol">
              <option class="form__option" *ngFor="let rol of role" value="{{rol.id}}">
                {{rol.nombre}}
              </option>
            </select>
          </div>

          <div class="text-center">
            <button class="btn btn-primary btnModal col-lg-4 col-sm-4"
              [disabled]="registerForm.invalid">Guardar</button>
          </div>
        </form>
      </div>
    </div>
    <div class="container" style="padding-left: 50px; padding-right: 50px;">
    </div>
    <!-- Footer -->
    <div class="position-sticky animacionSecundaria" style="top: 120vh;">
      <footer>
        <div class="container text-center p-3">
          <blockquote class="blockquote" style="font-size: 18px; color: black; font-weight: 400; margin-bottom: 0%;">
            Copyright © 2022 · <a href="/desarrolladores" style="text-decoration: none; color: black"
              target="_blank">Gestión TIC e Innovación Tecnológica</a> | <a href="https://www.corhuila.edu.co"
              style="text-decoration: none; color: black;" target="_blank">Corporación Universitaria del Huila -
              CORHUILA</a>
          </blockquote>
        </div>
      </footer>
    </div>
  </div>
</div>
