// importar los modulos del router
import { ModuleWithProviders } from "@angular/core";
//import { Routes, RouterModule} from '@angular/router';
import { Routes, RouterModule } from '@angular/router';
// importar componentes
import { LoginComponent } from "./components/login/login.component";
import { InicioComponent } from "./components/inicio/inicio.component";
import { UsuariosComponent } from "./components/usuarios/usuarios.component";
import { RegisterComponent } from "./components/register/register.component";
import { EditarUsuarioComponent } from "./components/editar-usuario/editar-usuario.component";
import { TarjetonesComponent } from "./components/tarjetones/tarjetones.component";
import { CargoVotantesComponent } from "./components/cargo-votantes/cargo-votantes.component";
import { RegistroVotantesComponent } from "./components/registro-votantes/registro-votantes.component";
import { RegistroTarjetonesComponent } from "./components/registro-tarjetones/registro-tarjetones.component";
import { ErrorComponent } from "./components/error/error.component";
import { ListaVotacionComponent } from "./components/lista-votacion/lista-votacion.component";
import { FinalvotacionComponent } from "./components/finalvotacion/finalvotacion.component";


//
import { IdentityGuard } from "./service/identity.guard";
import { NoIdentityGuard } from "./service/no.identity";
import { VotacionComponent } from "./components/votacion/votacion.component";
import { IdentityVotanteGuard } from './service/identity-votante.guard';

// array de rutas
const appRoutes: Routes = [
  { path: '', component: LoginComponent, canActivate: [NoIdentityGuard] },
  { path: 'login', component: LoginComponent, canActivate: [NoIdentityGuard] },
  { path: 'inicio', component: InicioComponent, canActivate: [IdentityGuard] },
  { path: 'usuarios', component: UsuariosComponent, canActivate: [IdentityGuard] },
  { path: 'registro-usuario', component: RegisterComponent, canActivate: [IdentityGuard] },
  { path: 'editar-usuario/:id', component: EditarUsuarioComponent, canActivate: [IdentityGuard] },
  { path: 'tarjetones', component: TarjetonesComponent, canActivate: [IdentityGuard] },
  { path: 'cargo-votantes', component: CargoVotantesComponent, canActivate: [IdentityGuard] },
  { path: 'registro-votantes', component: RegistroVotantesComponent, canActivate: [IdentityGuard] },
  { path: 'registro-tarjeton', component: RegistroTarjetonesComponent, canActivate: [IdentityGuard] },
  { path: 'votacion', component: VotacionComponent, canActivate: [IdentityVotanteGuard] },
  { path: 'resultados', component: ListaVotacionComponent, canActivate: [IdentityGuard]},
  { path: 'votacion-finalizada', component: FinalvotacionComponent},
  { path: '**', component: ErrorComponent }
]
// exportar configuracion
export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes);
