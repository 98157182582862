import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TarjetonService } from 'src/app/service/tarjeton.service';
import Swal from 'sweetalert2';
import { IdentityGuard } from 'src/app/service/identity.guard';
import { VotacionService } from 'src/app/service/votacion.service';

@Component({
  selector: 'app-votacion',
  templateUrl: './votacion.component.html',
  styleUrls: ['./votacion.component.css'],
  providers: [
    UserService,
    TarjetonService,
    VotacionService
  ]
})
export class VotacionComponent implements OnInit {

  public page_title: any;
  public identity: any;
  public date: any;
  public tarjetonPlancha: any = [];
  // spinner carga
  public mostrarCarga: any;
  public status: string;
  public voto: any = { "planchas": [{ id_plancha: "", id_tarjeton: "" }] };
  public contador_tarjtones: any;
  public enviarListadoPlanchas: any = [];


  constructor(private _userService: UserService,
    private _tarjetonService: TarjetonService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _validarToken: IdentityGuard,
    private _votacionService: VotacionService) {
    this.page_title = "VOTACIÓN";
    this.identity = this._userService.getIdentity();
    this.mostrarCarga = "block";
    this.status = '';
  }

  ngOnInit(): void {
    setTimeout((d: any) => {
      if(sessionStorage.getItem('tokenVotacionUH')!=null){
        this._validarToken.validarToken();
        console.log("Si debe salir");
      }else{
        console.log("No hace nada");
      }
    }, 588000);
    this.getTarjetones();
  }

  getTarjetones() {
    this._tarjetonService.getTarjetonesPlanchas().subscribe(
      response => {
        if (response.status == 'Token is Expired') {
          this._validarToken.validarToken();
        }
        else if (response.data.length != 0) {
          //debugger;
          //var example = JSON.stringify(response.data)
          //http://jsonviewer.stack.hu/

          this.status = "success";

          for (let i = 0; i < response.data.length; i++) {
            var planchas = response.data[i].planchas;
            for (let x = 0; x < planchas.length; x++) {
              if (planchas[x].nombre_plancha == "VOTO NO MARCADO") {
                response.data[i].planchas[x].marcado = true;
              } else {
                response.data[i].planchas[x].marcado = false;
              }
            }
          }

          this.tarjetonPlancha = response.data;
          this.contador_tarjtones = this.tarjetonPlancha.length;
          this.mostrarCarga = "none";
        } else {
          this.mostrarCarga = "none";
          this.status = "error";
        }
      }, error => {
        this.mostrarCarga = "none";
        this.status = "error";
      }
    );
  }

  seleccionCard(e: any, planchaSeleccionado: any, tarjeton: any) {
    //debugger;

    let result = this.tarjetonPlancha.filter((t: any) => {
      return (t.id == tarjeton.id)
    });

    if (result.length > 0) {
      for (let i = 0; i < result.length; i++) {
        for (let x = 0; x < result[i].planchas.length; x++) {
          if (result[i].planchas[x].id == planchaSeleccionado.id) {
            result[i].planchas[x].marcado = true;
            //this.color_card(result[i].planchas[x].id, result[i].planchas[x].id_tarjeton, '');
          } else {
            result[i].planchas[x].marcado = false;
            // this.color_card(result[i].planchas[x].id,  result[i].planchas[x].id_tarjeton, '#fff');
          }
        }
      }
    }

    //Actualizar listado global con los nuevos estados
    for (let i = 0; i < this.tarjetonPlancha.length; i++) {
      if (result.id == this.tarjetonPlancha[i]) {
        this.tarjetonPlancha[i] = result;
      }
    }
  }

  guardar() {
    this.enviarListadoPlanchas = [];

    //Actualizar listado global con los nuevos estados
    for (let i = 0; i < this.tarjetonPlancha.length; i++) {
      for (let x = 0; x < this.tarjetonPlancha[i].planchas.length; x++) {
        if (this.tarjetonPlancha[i].planchas[x].marcado == true) {
          this.enviarListadoPlanchas.push({ id_plancha: this.tarjetonPlancha[i].planchas[x].id, id_tarjeton: this.tarjetonPlancha[i].planchas[x].id_tarjeton, nombre_plancha: this.tarjetonPlancha[i].planchas[x].nombre_plancha });

        }
      }
    }
    for (let y = 0; y < this.enviarListadoPlanchas.length; y++) {
      if (this.enviarListadoPlanchas[y].nombre_plancha == "VOTO NO MARCADO") {
        Swal.fire({
          title: '<strong>TERMINAR VOTACIÓN</strong>',
          icon: 'question',
          text: '¿Esta seguro de no marcar ninguna opción?',
          showCancelButton: true,
          confirmButtonText: 'VOTAR',
          confirmButtonColor: '#007bff',
          cancelButtonColor: '#dc3545',
          cancelButtonText: 'CANCELAR',
          reverseButtons: false,
          width: 600,
        }).then((result) => {
          // confirmación para votar
          if (result.isConfirmed) {
            this.votocion();
          } else {
            this.alertas('error', 'Votación Incorrecta', 'Se Cancelo su Votación');
            this.getTarjetones();
          }
        })
      } else {
        Swal.fire({
          title: '<strong>TERMINAR VOTACIÓN</strong>',
          icon: 'question',
          text: '¿Esta seguro de la opción marcada?',
          showCancelButton: true,
          confirmButtonText: 'VOTAR',
          confirmButtonColor: '#007bff',
          cancelButtonColor: '#dc3545',
          cancelButtonText: 'CANCELAR',
          reverseButtons: false,
          width: 600,
        }).then((result) => {
          // confirmación para votar
          if (result.isConfirmed) {
            this.votocion();
          } else {
            this.alertas('error', 'Votación Incorrecta', 'Se Cancelo su Votación');
            this.getTarjetones();
          }
        })
      }
    }
  }

  votocion() {
    this._votacionService.add({ planchas: this.enviarListadoPlanchas }).subscribe(
      response => {
        if (response.status == 'Token is Expired') {
          this._validarToken.validarToken();
        }
        else if (response.respuesta == 'ERROR') {
          this.alertas('error', 'Oppss...', response.mensaje);
        } else {
          this.alertas('success', 'Votación Correcta', 'Gracias por su Voto.');
          this._router.navigate(['votacion-finalizada']);
          sessionStorage.clear();
        }
      }, error => {
        this.alertas('error', 'Opss', 'No pudimos obtener su Voto.');
      }
    );
  }

  alertas(icon: any, title: any, text: any) {
    Swal.fire({
      icon: icon,
      title: title,
      text: text,
      timer: 4000,
      timerProgressBar: true,
    })
  }

}

