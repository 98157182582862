import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { routing, appRoutingProviders } from './app.routing';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { RegisterComponent } from './components/register/register.component';
import { EditarUsuarioComponent } from './components/editar-usuario/editar-usuario.component';
import { TarjetonesComponent } from './components/tarjetones/tarjetones.component';
import { CargoVotantesComponent } from './components/cargo-votantes/cargo-votantes.component';
import { RegistroVotantesComponent } from './components/registro-votantes/registro-votantes.component';
import { RegistroTarjetonesComponent } from './components/registro-tarjetones/registro-tarjetones.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { IdentityGuard } from './service/identity.guard';
import { NoIdentityGuard } from './service/no.identity';
import { UserService } from './service/user.service';
import { ErrorComponent } from './components/error/error.component';
import { AlifeFileToBase64Module } from 'alife-file-to-base64';
import { MomentModule } from 'angular2-moment';


import { MatTableModule } from '@angular/material/table'
import {MatPaginatorModule} from '@angular/material/paginator';
import { VotacionComponent } from './components/votacion/votacion.component';
import { ListaVotacionComponent } from './components/lista-votacion/lista-votacion.component';
import { FinalvotacionComponent } from './components/finalvotacion/finalvotacion.component';

@NgModule({

  declarations: [
    AppComponent,
    LoginComponent,
    InicioComponent,
    NavbarComponent,
    UsuariosComponent,
    RegisterComponent,
    EditarUsuarioComponent,
    TarjetonesComponent,
    CargoVotantesComponent,
    RegistroVotantesComponent,
    RegistroTarjetonesComponent,
    ErrorComponent,
    VotacionComponent,
    ListaVotacionComponent,
    FinalvotacionComponent,

  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    routing,
    SweetAlert2Module.forRoot(),
    SweetAlert2Module,
    SweetAlert2Module.forChild({}),
    BrowserAnimationsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    AlifeFileToBase64Module,
    MomentModule,

  ],
  providers: [
    appRoutingProviders,
    IdentityGuard,
    NoIdentityGuard,
    UserService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
