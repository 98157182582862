import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { global } from "./global";
import { Tarjeton } from "../models/tarjetone";

@Injectable()
export class TarjetonService {

  public url: string;

  constructor(private http: HttpClient) {
    this.url = global.url;
  }

  public headers = new HttpHeaders(
    {
      Authorization: `Bearer ${sessionStorage.getItem('tokenVotacionUH')}`,
    }
  );
  // crear tarjeton
  registerTarjeton(data: any) {
    return this.http.post(this.url + 'crearTarjeton', data, { headers: this.headers });
  }
  // obtener los tarjetones
  getTarjetones(): Observable<any> {
    return this.http.get(this.url + 'obtenerTarjeton', { headers: this.headers });
  }
  // obtner tarjeton por id
  getTarjeton(id: any): Observable<any> {
    return this.http.get(this.url + 'obtenerTarjeton/' + id, { headers: this.headers });
  }
  // actualizar tarjeton
  updateTarjeton(id: any, data: any) {
    return this.http.put(this.url + 'actualizarTarjeton/' + id, data, { headers: this.headers });
  }
  // eliminar tarjeton
  deleteTarjeton(id: any) {
    return this.http.delete(this.url + 'eliminarTarjeton/' + id, { headers: this.headers });
  }
  // listar tipos de cargos
  getCargos(): Observable<any> {
    return this.http.get(this.url + 'cargos', { headers: this.headers })
  }
  // obtener tarjetone con planchas
  getTarjetonesPlanchas(): Observable<any> {
    return this.http.get(this.url + 'tarjetones_votacion', { headers: this.headers });
  }
  resultados(id_tarjeton: any): Observable<any>{
    return this.http.post(this.url+'resumen/votacion_tarjeton/'+id_tarjeton, {});
  }


}
