import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cargo-votantes',
  templateUrl: './cargo-votantes.component.html',
  styleUrls: ['./cargo-votantes.component.css']
})
export class CargoVotantesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
