import { Component, OnInit } from '@angular/core';
import { VotacionService } from 'src/app/service/votacion.service';
import { UserService } from 'src/app/service/user.service';
import { IdentityGuard } from 'src/app/service/identity.guard';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-votacion',
  templateUrl: './lista-votacion.component.html',
  styleUrls: ['./lista-votacion.component.css'],
  providers: [
    UserService,
    VotacionService
  ]
})
export class ListaVotacionComponent implements OnInit {

  public identity: any;
  public page_title: string;
  public mostrarCarga: any;
  public data: any;

  public planchas: any = [];
  public votos: any = [];



  constructor(private _userService: UserService,
    private _votacionService: VotacionService,
    private _identityGuard: IdentityGuard
  ) {
    this.identity = this._userService.getIdentity();
    this.page_title = "RESULTADOS";
    this.mostrarCarga = "block";
  }

  ngOnInit(): void {
    this.getVotaciones();
  }

  getVotaciones() {
    this._votacionService.getVotaciones().subscribe(
      response => {
        if (response.status == 'Token is Expired') {
          this._identityGuard.validarToken();
        }else{
          this.mostrarCarga = "none";
          this.data = response.data;
          for (let datos of response.data) {
            for (let planchas of datos.planchas) {
              this.planchas.push(planchas.nombre_plancha);
              this.votos.push(planchas.votos);
            }
          }
        }
      }, error => {
        console.log(error);
      }
    );
  }

  resumenVotacion() {
    this.mostrarCarga="block";
    this._votacionService.resumenVotacion().subscribe(
      response => {
        this.mostrarCarga="none";
        this.alert('success', 'Resumen Votación', 'Por favor, revisar bandeja de entrada para ver el resumen de la votación');
      }, error => {
        this.mostrarCarga="none";
        this.alert('error', 'Opss...', 'No se logro enviar el resumen de la votación');
      }
    );
  }

  alert(icon: any, title: any, text: any) {
    Swal.fire({
      icon: icon,
      title: title,
      text: text,
      timer: 4000,
      timerProgressBar: true,
    })
  }


}
