<title> Votaciones | Inicio de sesión</title>
<!-- SPINNER -->
<div [ngStyle]="{'display':mostrarCarga}" class="modal" data-keyboard="false" tabindex="-1"
  aria-labelledby="exampleModalLabel">
  <div id="config-spinner">
    <div class="text-center">
      <div class="modal-dialog">
        <div class="spinner-border configSpinner" role="status">
          <span class="visually-hidden modal sr-only"></span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex">
  <div id="contenido" class="w-100">
    <div class="row justify-content-center align-items-center">
      <!-- Configuración del logo  -->
      <div class="col-lg-5">
        <img class="logoCorhuilaBlanco" src="./assets/img/imagen_inicio_2.png">
      </div>
      <div class="col-lg-7 seccion-iniciarSesion">
        <div class="margen-camposForm">
          <!-- Título -->
          <div class="marginCamposInicioSesion mb-4">
            {{page_title}}
          </div>
          <div class="container mt-5">
            <!-- Campos de ingreso -->
            <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">
              <div class="form-control-config mb-4">
                <label class="text-center">
                  <span  style="font-size: 18px; font-weight: 400;">Correo Electrónico Institucional</span>
                </label>
                <input type="text" name="txtCorreo" class="form-control" autofocus="autofocus" #email="ngModel"
                  [(ngModel)]="user.email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder="Correo institucional">
                <small *ngIf="!email.valid && email.touched" class="invalid-feedback d-block">Ingresa su Correo
                  Electrónico Institucional</small>
              </div>
              <div class="form-control-config mb-2">
                <label class="text-center">
                  <span  style="font-size: 18px;  font-weight: 400;">Número de Identificación</span>
                </label>
                <input type="password" name="txtContrasena" required="" class="form-control" #password="ngModel"
                  [(ngModel)]="user.password" placeholder="Número de identificación">
                <small *ngIf="!password.valid && password.touched" class="invalid-feedback d-block">Ingresa su número de identificación</small>
              </div>
              <!-- Botón de inicio de sesión -->
              <div class="col-12 text-center">
                <button class="btn btn-primary botonConfig" [disabled]="loginForm.invalid">Ingresar</button>
              </div>
            </form>
          </div>
        </div>
        <!-- Footer -->
        <div class="position-sticky container" style="top: 100vh;">
          <footer>
            <div class="container text-center p-3">
              <blockquote class="blockquote"
                style="font-size: 15px; color: black; font-weight: 400; margin-bottom: 0%;">
                Copyright © 2022 · <a href="/desarrolladores" style="text-decoration: none; color: black"
                  target="_blank">Gestión TIC e Innovación Tecnológica</a> | <a href="https://www.corhuila.edu.co"
                  style="text-decoration: none; color: black;" target="_blank">Corporación Universitaria del Huila -
                  CORHUILA</a>
              </blockquote>
            </div>
          </footer>
        </div>
      </div>
    </div>

  </div>
</div>
