import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-finalvotacion',
  templateUrl: './finalvotacion.component.html',
  styleUrls: ['./finalvotacion.component.css']
})
export class FinalvotacionComponent implements OnInit {

  public page_title = "VOTACIÓN FINALIZADA"

  constructor(private _router: Router) { }

  ngOnInit(): void {
  }

  inicioSesion(){
    sessionStorage.clear();
    this._router.navigate(['login']);
  }

}
