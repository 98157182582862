import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import Swal from "sweetalert2";
import { UserService } from "./user.service";

//debemos importar este clase a la clase de rutas y al app.module para que nos pueda funcionar


@Injectable()
export class IdentityGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _userService: UserService,
  ) {
  }

  // metodo canActivete para identificar si el usuario esta identificado para que pueda acceder a las rutas
  canActivate() {
    let id_rol = this._userService.getIdentity();
    if (id_rol === 1) {
      return true;
    } else {
      sessionStorage.removeItem('name');
      sessionStorage.removeItem('tokenVotacionUH');
      this._router.navigate(['']);
      return false;
    }
  }
  validarToken() {
    sessionStorage.clear();
    Swal.fire({
      title: 'Tu sesión ha caducado',
      text: "Por favor volver a inicio",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this._router.navigate(['login'])
      }
    })
  }
}
