import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Plancha } from "../models/plancha";
import { global } from "./global";

@Injectable()
export class PlanchaService {

  public url: string;

  constructor(private _http: HttpClient) {
    this.url = global.url;
  }

  public headers = new HttpHeaders(
    {
      Authorization: `Bearer ${sessionStorage.getItem('tokenVotacionUH')}`,
    }
  );
  // crear plancha
  add(plancha: any): Observable<any> {
    return this._http.post(this.url + "planchas", plancha, { headers: this.headers });
  }
  // obtener las planchas del tarjetón
  getPlanchaTarjeton(id: any): Observable<any> {
    return this._http.get(this.url + "planchas_tarjeton/" + id, { headers: this.headers });
  }
  // buscar tarjeton por id
  getId(id: any): Observable<any> {
    return this._http.get(this.url + "planchas/" + id, { headers: this.headers });
  }
  // actualizar tarjeton por id
  update(id: any, plancha: any): Observable<any> {
    return this._http.put(this.url + "planchas/" + id, plancha, { headers: this.headers });
  }
}
