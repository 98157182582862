import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  public page_title: string;


  constructor(private _router: Router) {
    this.page_title="¿Te has perdido?";
  }

  ngOnInit(): void {
  }
  inicioSesion(){
    this._router.navigate(['login']);
  }

}
