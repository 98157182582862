export class Tarjeton{
  constructor(
    public id: string,
    public nombre_tarjeton: string,
    public fecha_inicio: string,
    public fecha_cierre: string,
    public id_user: any,
    public id_estado: any,
    public id_dirigido: any,
    public id_rol: any,
    public rol_name: any,
    public dirigido_name: any
  ){}
}
