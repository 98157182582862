<title> Votaciones | Votación</title>
<!-- SPINNER -->
<div [ngStyle]="{'display':mostrarCarga}" class="modal" data-keyboard="false" tabindex="-1"
  aria-labelledby="exampleModalLabel">
  <div id="config-spinner">
    <div class="text-center">
      <div class="modal-dialog">
        <div class="spinner-border configSpinner" role="status">
          <span class="visually-hidden modal sr-only"></span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex">

  <!-- -------------- -->
  <!--    SLIDEBAR    -->
  <!-- -------------- -->
  <div style="position: absolute; z-index: 1;">
    <app-navbar></app-navbar>
  </div>
  <div style="z-index:0;" id="contenido" class="w-100">
    <div class="configTituloPricipal" style="margin-top: 100px;">{{page_title}}</div>
    <h3 style="font-weight: 600; margin-left: 5%;">RECUERDE QUE LAS VOTACIONES CIERRAN A LAS 5:00 P.M.</h3>
    <hr>
    <div class="alert alert-warning ml-4 mr-4 mt-3 text-center" role="alert" *ngIf="status=='error'">
      No se pueden cargar los tarjetones porque las votaciones ya cerraron.
    </div>

    <!-- Tarjetones -->
    <section class="active contenedor row" *ngFor="let tarjeton of tarjetonPlancha">
      <div class="titleTarjeton">
        ELECCIÓN REPRESENTANTE DE LOS ESTUDIANTES AL {{tarjeton.cargo}}.
      </div>
      <!--PLANCHA-->
      <div class="inicio" *ngFor="let plancha of tarjeton.planchas" (click)="seleccionCard($event, plancha, tarjeton)">

        <!---->
        <div class="card col-sm-12"  id="card{{plancha.id_tarjeton+'-'+plancha.id}}" *ngIf="plancha.nombre_plancha!='VOTO NO MARCADO'">
          <img id="img{{plancha.id_tarjeton+'-'+plancha.id}}"
            style="position: absolute;height:270px;width:300px;z-index: 1; margin-left: 5%;"
            *ngIf="plancha.marcado"
            class="animacion-marcax"
            src="./assets/img/marcaX.png">
          <div class="plancha">
            <h2 id="titleplancha" class="mt-1">{{plancha.nombre_plancha}}</h2>
          </div>
          <div class="carddos"
            *ngIf="plancha.nombre_plancha!='VOTO EN BLANCO' && plancha.nombre_plancha!='VOTO NO MARCADO'">
            <div class="content">
              <h2 id="titleNombreCandidatoP">CANDIDATO PRINCIPAL</h2>
              <div class="img">
                <img class="none" src="{{plancha.foto_principal}}" *ngIf="plancha.foto_principal!=null">
                <img class="none" src="./assets/img/image_error.jpg" *ngIf="plancha.foto_principal==null">
              </div>
              <h5>{{plancha.principal}}</h5>
            </div>
            <div class="content">
              <h2 id="titleNombreCandidatoC">CANDIDATO SUPLENTE</h2>
              <div class="img">
                <img class="none" src="{{plancha.foto_suplente}}" *ngIf="plancha.foto_suplente!=null">
                <img class="none" src="./assets/img/image_error.jpg" *ngIf="plancha.foto_suplente==null">
              </div>
              <h5>{{plancha.suplente}}</h5>
            </div>
          </div>
        </div>

      </div>
    </section>

    <!-- Boton de votar -->
    <div class="row">
      <div class="col-md-5"></div>
      <div style="text-align:center" class="cardbtn col-md-2">
        <button style="width: 200px;" class="btn btn-dark" (click)="guardar()" *ngIf="status=='success'">ENVIAR
          VOTO</button>
        <button style="width: 200px;" class="btn btn-dark" *ngIf="status=='error'"
          style="display: none;">ENVIAR
          VOTO</button>
      </div>
      <div class="col-md-5"></div>
    </div>
    <!-- Footer -->
    <div class="position-sticky animacionSecundaria" style="top: 120vh;">
      <footer>
        <div class="container text-center p-3">
          <blockquote class="blockquote" style="font-size: 18px; color: black; font-weight: 400; margin-bottom: 0%;">
            Copyright © 2022 · <a href="/desarrolladores" style="text-decoration: none; color: black"
              target="_blank">Gestión TIC e Innovación Tecnológica</a> | <a href="https://www.corhuila.edu.co"
              style="text-decoration: none; color: black;" target="_blank">Corporación Universitaria del Huila -
              CORHUILA</a>
          </blockquote>
        </div>
      </footer>
    </div>
  </div>
