export class Plancha{
  constructor(
    public id: string,
    public nombre_plancha: string,
    public id_tarjeton: any,
    public id_estado: any,
    public id_principal: any,
    public id_secundario: any
  ){}
}
