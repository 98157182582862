<title> Votaciones | Inicio de sesión</title>
<div class="d-flex">
  <div id="contenido" class="w-100">
    <div class="row justify-content-center align-items-center">
      <!-- Configuración del logo  -->
      <div class="col-lg-5">
        <img class="logoCorhuilaBlanco" src="./assets/img/imagen_inicio_2.png">
      </div>

      <div class="col-lg-7 seccion-iniciarSesion">
        <div class="margen-camposForm">
          <!-- Título -->
          <div class="marginCamposInicioSesion mb-4 mb-2">
            <h3 style="font-weight: 700; font-size: 30px">{{page_title}}</h3><br>
            <img style="width: 300px; height: 200px" src="./assets/img/resultado_image.png">
            <div class="text-center mt-4">
              <p class="fs-4">HOY MÁS QUE NUNCA, <span class="text-success">TU VOTO</span></p>
              <p class="fs-4">
                HARÁ LA DIFERENCIA.
              </p><br>
              <button class="btn btn-primary btn-modal col-lg-2 col-sm-5" (click)="inicioSesion()">Iniciar Sesión</button>
            </div>
          </div>
          <div class="container">
            <!-- Campos de ingreso -->
          </div>
        </div>
        <!-- Footer -->
        <div class="position-sticky container" style="top: 100vh;">
          <footer>
            <div class="container text-center p-3">
              <blockquote class="blockquote"
                style="font-size: 15px; color: black; font-weight: 400; margin-bottom: 0%;">
                Copyright © 2022 · <a href="/desarrolladores" style="text-decoration: none; color: black"
                  target="_blank">Gestión TIC e Innovación Tecnológica</a> | <a href="https://www.corhuila.edu.co"
                  style="text-decoration: none; color: black;" target="_blank">Corporación Universitaria del Huila -
                  CORHUILA</a>
              </blockquote>
            </div>
          </footer>
        </div>
      </div>
    </div>

  </div>
</div>
