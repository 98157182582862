import { Component, OnInit } from '@angular/core';
import { global } from 'src/app/service/global';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/service/user.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-registro-tarjetones',
  templateUrl: './registro-tarjetones.component.html',
  styleUrls: ['./registro-tarjetones.component.css'],
  providers: [UserService],
})
export class RegistroTarjetonesComponent implements OnInit {
  public page_title: string;
  public identity: any;
  public user: any;
  myControl = new FormControl('');
  options: string[] = [];
  filteredOptions: Observable<string[]>;

  constructor(private _userService: UserService) {
    this.page_title = 'Registro Tarjetones';
    this.identity = this._userService.getIdentity();
    this.user = Array(User);

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );

  }

  ngOnInit(): void {
  }

  getUsuario(data:any) {
    console.log(data);
    if(data.lenght>5){

    }

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
