<div style="z-index: -1;" class="page">
  <header tabindex="0">
    <div class="col-md-5">
      <span style="color: width; margin-left: 70px;" class="nombre-usuario">VOTE</span>
    </div>
    <div style="text-align:end;margin-right:5px" class="col-md-7">
      <svg style="margin-right: 10px;" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor"
        class="bi bi-person-circle" viewBox="0 0 16 16" *ngIf="!identity.foto">
        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
        <path fill-rule="evenodd"
          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
      </svg>
      <span style="margin-right: 10px;" class="nombre-usuario">{{identity.name}}</span>
      <a type="button" (click)="logout()"><svg style="margin-right: 10px" width="24px" height="24px" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <path
          d="M4,12a1,1,0,0,0,1,1h7.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H5A1,1,0,0,0,4,12ZM17,2H7A3,3,0,0,0,4,5V8A1,1,0,0,0,6,8V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v3a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V5A3,3,0,0,0,17,2Z" />
      </svg></a>
    </div>
  </header>
  <div id="nav-container">
    <div class="bg"></div>
    <div class="button" tabindex="0">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </div>
    <div id="nav-content" tabindex="0">
      <div class="d-flex flex-column flex-shrink-0 p-1 text-white" style="width: 200px;">
        <a [routerLink]="['/inicio']"
          class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
          <svg class="me-3" width="40" height="32"></svg>
          <div class="text-center" style="margin-left: auto; margin-right: auto;">
            <img class="configLogoCorhuila" src="/assets/img/LogoCorhuilaBlanco.png">
          </div>
        </a>
        <br>
        <div class="container">
          <div class="car" style="border: 1px solid rgba(255, 255, 255, 0.327);"></div>
        </div>
        <br>
        <ul class="nav nav-pills flex-column mb-auto" *ngIf="identity.id_rol=='1'">
          <!--- HOME -->
          <button class="botonGeneral" style="margin-top: 0%">
            <li class="nav-item">
              <a [routerLink]="['/inicio']" class="nav-link text-white">
                <svg class="me-1" width="0" height="0"></svg>
                <span class="textSlideBar">Inicio</span>
              </a>
            </li>
          </button>
          <!-- TARJETONES -->
          <button class="botonGeneral" style="margin-top: 0%;">
            <li class="nav-item">
              <a [routerLink]="['/tarjetones']" class="nav-link text-white">
                <svg class="me-1" width="0" height="0"></svg>
                <span class="textSlideBar">TARJETONES</span>
              </a>
            </li>
          </button>
          <!-- VOTACION --->
          <button class="botonGeneral" style="margin-top: 0%;">
            <li class="nav-item">
              <a [routerLink]="['/votacion']" class="nav-link text-white">
                <svg class="me-1" width="0" height="0"></svg>
                <span class="textSlideBar">VOTACIÓN</span>
              </a>
            </li>
          </button>
          <!-- MENU USUARIOS -->
          <button class="botonGeneral">
            <li class="nav-item">
              <a [routerLink]="['/usuarios']" class="nav-link text-white">
                <svg class="me-1" width="0" height="0"></svg>
                <span class="textSlideBar">Usuarios</span>
              </a>
            </li>
          </button>
          <!-- VOTANTES -->
          <button class="botonGeneral">
            <li class="nav-item">
              <a [routerLink]="['/registro-votantes']" class="nav-link text-white">
                <svg class="me-1" width="0" height="0"></svg>
                <span class="textSlideBar">Registro de Votantes</span>
              </a>
            </li>
          </button>
          <!-- RESULTADOS -->
          <button class="botonGeneral">
            <li class="nav-item">
              <a [routerLink]="['/resultados']" class="nav-link text-white">
                <svg class="me-1" width="0" height="0">cle"/></svg>
                <span class="textSlideBar">Lista de Votaciones</span>
              </a>
            </li>
          </button>
          <!-- BOTON CERRAR SESIÓN -->
          <div class="configBotonFoot text-center p-4">
            <button class="botonCerrarSesion">
              <li class="nav-item">
                <a class="nav-link" (click)="logout()">
                  <svg class="me-1" width="0" height="0">cle"/></svg>
                  Cerrar Sesión
                </a>
              </li>
            </button>
          </div>
        </ul>
        <!-- MENU PARA LOS VOTANTES -->
        <ul class="nav nav-pills flex-column mb-auto" *ngIf="identity.id_rol=='4' || identity.id_rol=='5'">
          <!-- VOTACION --->
          <button class="botonGeneral" style="margin-top: 0%;">
            <li class="nav-item">
              <a [routerLink]="['/votacion']" class="nav-link text-white">
                <svg class="me-1" width="0" height="0"></svg>
                <span class="textSlideBar">VOTACIÓN</span>
              </a>
            </li>
          </button>
          <!-- BOTON CERRAR SESIÓN -->
          <div class="configBotonFoot text-center p-4">
            <button class="botonCerrarSesion">
              <li class="nav-item">
                <a class="nav-link" (click)="logout()">
                  <svg class="me-1" width="0" height="0">cle"/></svg>
                  Cerrar Sesión
                </a>
              </li>
            </button>
          </div>
        </ul>
      </div>
    </div>
  </div>
