import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  providers: [
    UserService
  ]
})
export class NavbarComponent implements OnInit {

  public identity: any;

  constructor(private _router: Router,
    private _userService: UserService) {
    this.identity = this._userService.getUser();
  }
  ngOnInit(): void {
  }

  logout() {
    sessionStorage.clear();
    this._router.navigate(['login']);
  }

}
