import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { post } from 'jquery';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/service/user.service';
import Swal from 'sweetalert2';
import { global } from 'src/app/service/global';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [
    UserService
  ],

})
export class LoginComponent implements OnInit {

  public page_title: string;
  public user: any;
  public status: string;
  public message_alert: any;

  public url: any;
  public mostrarCarga = "none";
  public men: string;
  public id: any;

  constructor(
    private _userService: UserService,
    private _router: Router,
  ) {
    this.user = new User(1, '', '', '', '', '', '', '', '', '', '', '', '');
    this.status = '';
    this.page_title = 'Iniciar Sesión';
    this.message_alert = '';
    this.url = global.url;
    this.men = "";
  }

  private patronCorreo: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  public formCorreo = {
    email: null
  }

  ngOnInit(): void {
    this.modalTerminos();
  }

  onSubmit(form: any) {
    this.mostrarCarga = "block";
    this._userService.signup(this.user).subscribe(( { status, message}: { status: number, message: string } ) => {
      this.mostrarCarga = "none";
      if(status === 200 && message === 'OK' ){
        this.modalCodigoVerificacion();
      }else {
        // No puede ingresar mas al Sistema
        this.alertas(message);
      }
    }, error => {
      // Error de credenciales
      const { error: messageError } = error.error;
      this.mostrarCarga = "none";
      this.alertas(messageError);
    });
    form.reset();
  }

  // dialogos de error
  alertas(message: any) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      html: message
    })
  }

  restablecerClave(form: any) {
    const controlEmail = new FormControl(form.value.email, [Validators.required, Validators.pattern(this.patronCorreo), Validators.maxLength(50)]);
    if (controlEmail.invalid) {
      this.validarCorreo(controlEmail);
      this.formCorreo.email = null;
    }
    if (controlEmail.valid) {
      this._userService.restablecimiento(this.formCorreo).subscribe(
        (response: any) => {
          this.mostrarAlerta(response.data);
          this.formCorreo.email = null;
        }, error => {
          console.log(error.error.error);
          this.mostrarAlerta(error.error.error);
          this.formCorreo.email = null;
        }
      );
    }
  }
  /******************
   * Validaciones
   ******************/
  validarCorreo(controlCorreo: any) {

    if (controlCorreo.errors?.required) {
      this.mostrarAlerta('El campo correo electrónico no puede quedar vacío');

    } else if (controlCorreo.errors?.maxlength) {
      this.mostrarAlerta('El campo correo no puede tener más de 50 caracteres');

    } else if (controlCorreo.errors?.pattern) {
      this.mostrarAlerta('Verifique el correo electrónico, la estructura ingresada es invalida');
    }
  }
  //Alerta de advertencias
  mostrarAlerta(texto: String) {
    Swal.fire({
      icon: 'warning',
      title: '¡Atención!',
      text: '' + texto,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#FF4D4D',
      allowOutsideClick: false

    });
  }
  // modal para validar de codigo de verificación
  modalCodigoVerificacion() {
    Swal.fire({
      title: '<strong>INGRESAR CODIGO DE AUTENTICACIÓN</strong>',
      text: 'Acabamos de enviar un código de autenticación a su Correo Electrónico Institucional',
      inputPlaceholder: 'Ingresa el código de autenticación',
      input: 'number',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      allowEscapeKey: false,
      confirmButtonText: 'Validar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        return post(this.url + 'usuario/codigo_verificacion', { token: login })
          .then(response => {
            const { respuesta, data } = response;
            if (respuesta == 'ERROR') {
              Swal.showValidationMessage( 'Código no valido.' );
            } else {
              sessionStorage.setItem('tokenVotacionUH', data[0].token);
              sessionStorage.setItem('name', JSON.stringify(data[0]));
              Swal.close();
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Usuario Autenticado, Bienvenido!',
                showConfirmButton: false,
                allowEscapeKey: false,
                timer: 2000
              });
              if(data[0].id_rol === 1){
                this._router.navigate(['inicio']);
              } else {
                this._router.navigate(['votacion']);
              }
            }
          }).catch(error => Swal.showValidationMessage(`Error al validar el Código.`))
      },
      allowOutsideClick: () => Swal.isLoading()
    })
  }
  // modal de aceptar terminos y condiciones
  modalTerminos() {
    (async () => {
      const { value: accept } = await Swal.fire({
        title: '<strong>TÉRMINOS Y CONDICIONES</strong>',
        html: 'Para continuar su autenticación, debe aceptar los términos y condiciones del tratamiento de datos conforme a lo dispuesto <a title="Descargar Terminos y Condiciones" href="./assets/img/Terminos y condiciones.pdf" download="Terminos y condiciones.pdf"><strong>Aquí.</strong></a>',
        input: 'checkbox',
        inputValue: 0,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: false,
        inputPlaceholder:
          'Estoy de acuerdo con los términos y condiciones',
        confirmButtonText:
          'Continuar',
        inputValidator: (result) => {
          if (result != '1') {
            this.men = "Tienes que estar de acuerdo con los T&C";
          } else {
            this.men = "";

          }
          return this.men;
        }
      })
      if (accept) {
        Swal.close();
      } else {
        this._router.navigate(['login']);
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'No se logro la Autenticación, Error inesperado',
          showConfirmButton: false,
          allowEscapeKey: false,
          timer: 2000
        });
      }
    })()
  }
}
