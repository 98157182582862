import { Component, OnInit } from '@angular/core';
import { global } from 'src/app/service/global';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css'],
  providers: [UserService]
})
export class InicioComponent implements OnInit {

  public page_title: string;
  public identity: any;
  public mostrarCarga: any;

  constructor(private _userService: UserService) {
    this.page_title="BIENVENIDOS";
    this.identity = this._userService.getIdentity();
  }

  ngOnInit(): void {
  }
}
