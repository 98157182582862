export class User {
  constructor(
    public id: number,
    public cedula: string,
    public name: string,
    public email: string,
    public password: string,
    public token: string,
    public id_rol: any,
    public id_tipo_cargo: any,
    public ingreso: any,
    public candidato: any,
    public id_estado: any,
    public foto: any,
    public id_facultad: any
  ) {
  }
}
