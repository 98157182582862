import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { global } from "./global";

@Injectable()
export class VotacionService {

  public url: string;

  constructor(private http: HttpClient) {
    this.url = global.url;
  }

  public headers = new HttpHeaders(
    {
      Authorization: `Bearer ${sessionStorage.getItem('tokenVotacionUH')}`,
    }
  );

  add(data: any): Observable<any> {
    return this.http.post(this.url + 'votar', data, { headers: this.headers });
  }
  getVotaciones(): Observable<any> {
    return this.http.get(this.url + 'dashboard', { headers: this.headers });
  }

  resumenVotacion(): Observable<any> {
    return this.http.post(this.url + 'resumen/votacion', {});
  }
}
