import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/service/user.service';
import Swal from 'sweetalert2';
import { Role } from 'src/app/models/role';
import { Facultad } from 'src/app/models/facultad';
import { IdentityGuard } from 'src/app/service/identity.guard';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { global } from 'src/app/service/global';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-registro-votantes',
  templateUrl: './registro-votantes.component.html',
  styleUrls: ['./registro-votantes.component.css'],
  providers: [
    UserService,
    IdentityGuard
  ]
})
export class RegistroVotantesComponent implements OnInit {

  public page_title: string;
  public status: string;
  public identity: any;
  public userCrear: any;
  public userEdit: any;
  public modalActualizar: any;
  public roles: any;
  public facultades: any;
  public patronEmail: any;
  displayedColumns: string[] = ['No. Documento', 'Nombre', 'Correo Electronico', "Poblacion Convocada", "Facultad", "Candidato", "Imagen", "Acciones"];
  user = new MatTableDataSource(Array(User));
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  public modalCrear: any;
  public mostrarCarga: any;

  public afuConfig = {
    multiple: false,
    formatsAllowed: ".jpg,.png,.gif",
    uploadAPI: {
      url: global.url + 'post/upload', //la ruta donde se sube la imagen
      headers: {
        "Authorization": `Bearer ${this._userService.getToken()}`
      }
    },
    theme: "attachPin",
    hideProgressBar: true,
    hideResetBtn: true,
    hideSelectBtn: false,
    replaceTexts: {
      attachPinBtn: 'Subir Imagen',
    }
  };

  constructor(
    private _userService: UserService,
    private _validarToken: IdentityGuard
  ) {
    this.page_title = "REGISTRO VOTANTES"
    this.identity = this._userService.getIdentity();
    this.status = "";
    this.userCrear = new User(1, '', '', '', '', '', '', '', '', false, '', '', '');
    this.userEdit = Array(User);
    this.roles = Array(Role);
    this.facultades = Array(Facultad);
    this.modalActualizar = "none";
    this.patronEmail = /\w+@corhuila.edu.co$/;
    this.modalCrear = "none";
    this.mostrarCarga = "block";
  }
  ngAfterViewInit() {
    this.user.paginator = this.paginator;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.user.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {
    this.getRoles();
    this.getFacultades();
    this.getUsers();
  }
  abrirModalCrear() {
    this.modalCrear = "block";
  }
  // crear Votante
  crearUser(form: any) {
    const email = new FormControl(form.value.email, [Validators.maxLength(50), Validators.pattern(this.patronEmail)]);
    const role = new FormControl(form.value.id_rol, [Validators.required]);
    const facultad = new FormControl(form.value.id_facultad, [Validators.required]);
    if (role.invalid) {
      this.alert('warning', 'Opss...', 'Por favor, Seleccione un Rol.');
    } else if (facultad.invalid) {
      this.alert('warning', 'Opss...', 'Por favor, Seleccione una Facultad.');
    } else if (email.invalid) {
      this.alert('warning', 'Opss...', 'El formato del correo electrónico no es válido, debe ser el institucional @corhuila.edu.co');
    } else {
      this._userService.register(this.userCrear).subscribe(
        response => {
          this.status = "success";
          this.alert('success', '', 'Candidado Creado Correctamente.');
          this.getUsers();
          form.reset();
          this.modalCrear = "none";
        }, error => {
          this.modalCrear = "none";
          form.reset();
          this.alert('error', 'Opss...', 'Por favor, Verificar tus datos.');
        }
      )
    }
  }
  // cancelar modal de crear votante
  cancelarModalCrear(form: any) {
    this.modalCrear = "none";
    form.reset();
  }
  // obtener usuarios
  getUsers() {
    this._userService.getUsers(2).subscribe(
      response => {
        if (response.status == 'Token is Expired') {
          this._validarToken.validarToken();
        } else if (response.respuesta == "OK") {
          this.status = response.respuesta;
          let result = response.data.map(function (obj: any) {
            return new User(obj.id, obj.cedula, obj.name, obj.email, obj.password, obj.token, obj.id_rol, obj.id_tipo_cargo, obj.ingreso, obj.candidato, obj.id_estado, obj.foto, obj.nombre_facultad);
          });
          this.user = new MatTableDataSource(result);
          this.user.paginator = this.paginator;
          this.mostrarCarga = "none";
        } else {
          this.mostrarCarga = "none";
          this.status = "error";
        }
      }, error => {
        this.status = "error";
      });
  }
  // obtener user Votantes id
  getUserid(id: any) {
    this._userService.getUserId(id).subscribe(
      response => {
        if (response.status == 'Token is Expired') {
          this._validarToken.validarToken();
        }else {
          this.status = "success";
          this.userEdit = response.data;
          this.modalActualizar = "block";
        }
      }, error => {
        this.status = "error";
      }
    );
  }
  // obtener roles
  getRoles() {
    this._userService.getRoles().subscribe(
      response => {
        if (response.status == 'Token is Expired') {
          this._validarToken.validarToken();
        }
        else if (response.respuesta == 'OK') {
          this.status = "success";
          // mostar los roles menos el 1 que es de Administrador
          this.roles = response.data.filter((b: any) => {
            return (b.id != '1' && b.id != '2')
          });
        }
      }, error => {
        this.status = "error";
      }
    );
  }
  getFacultades() {
    this._userService.getFacultades().subscribe(
      response => {
        this.status = "success";
        this.facultades = response.data;
      }, error => {
        this.status = "error";
      }
    );
  }
  // eliminar usuarios votantes
  deleteUser(id: any) {
    this._userService.deleteUser(id).subscribe(
      response => {
        if (response.status == 'Token is Expired') {
          this._validarToken.validarToken();
        }
        if (response.respuesta == "OK") {
          this.status = "success";
          this.alert(this.status, '', "Usuario Eliminado.");
          // volver a consultar el metodo para obtener los usuarios
          this.getUsers();
        } else {
          this.status = "error";
          this.alert(this.status, 'Opss...', "No se Elimino el Usuario.");
        }
      }, error => {
        this.status = "error";
        this.alert(this.status, 'Opss...', "Error de Conexión.");
      }
    );
  }

  // update de User Votantes
  onSubmitUpdate(form: any) {
    this._userService.updateUserId(this.userEdit.id, this.userEdit).subscribe(
      response => {
        if (response.status == 'Token is Expired') {
          this._validarToken.validarToken();
        }
        if (response.respuesta == 'OK') {
          this.status = "success";
          this.getUsers();
          this.modalActualizar = "none";
          this.alert("success", '', 'Votante Actualizado Exitosamente.');
        } else {
          this.status = "error";
          this.modalActualizar = "none";
          this.alert("warning", 'Opss...', 'No se pudo actualizar el Votante.');
        }
      }, error => {
        this.status = "error";
        this.modalActualizar = "none";
        this.alert(this.status, 'Opss...', error);
      }
    );
  }
  // cancelar modal edit Votantes
  modalEditCancelar() {
    this.modalActualizar = "none";
  }
  // estado candidato
  checkCandidato(id: any, value: any) {
    var estado = false;
    if (value == false) {
      estado = true;
    } else if (value == null) {
      estado = true;
    }
    this._userService.changeCandidato(id, estado).subscribe(
      response => {
        if (response.status == 'Token is Expired') {
          this._validarToken.validarToken();
        }else{
          this.alert('info', '', response.mensaje);
          this.getUsers();
        }
      }, error => {
      }
    );
  }
  // subir imagen del candidato
  imageUpload(id: any, e: any) {
    if (e[0].type == 'image/png' || e[0].type == 'image/jpg' || e[0].type == 'image/jpeg' || e[0].type == 'image/gif' || e[0].type == 'image/svg') {
      this._userService.image(id, e[0].base64).subscribe(
        response => {
          if (response.status == 'Token is Expired') {
            this._validarToken.validarToken();
          }
          else if (response.status_server == '200') {
            this.getUsers();
            this.alert('success', '', 'Se Almaceno la imagen correctamente');
          }else{
            this.getUsers();
            this.alert('error', '', 'No se puedo almacenar la imagen');
          }
        }, error => {
          this.alert('error', 'Opss...', 'No se pudo almacenar la imagen');
        }
      );
    } else {
      this.alert('error', 'Opss...', 'El archivo debe ser tipo Imagen');
    }

  }
  // modal de ver foto candidato
  fotoCandidato(img: any, users: any) {
    if (!img) {
      Swal.fire({
        title: '<strong> Opss... </strong><hr>',
        html: '<img src="./assets/img/image_error.jpg" style="width: 300px; height: 300px; overflow: hidden; border: 2px solid rgb(65, 65, 65);">',
        confirmButtonText: 'Volver',
        confirmButtonColor: '#0348db'
      })
    } else {
      Swal.fire({
        title: '<strong>' + users + '</strong><hr>',
        html: '<img src=' + img + ' style="width: 300px; height: 300px; overflow: hidden; border: 2px solid rgb(65, 65, 65);">',
        confirmButtonText: 'Volver',
        confirmButtonColor: '#0348db'
      })
    }
  }
  importar() {
    this.mostrarCarga="block";
    (async () => {
      const { value: file } = await Swal.fire({
        title: 'Pasos para Importar Votantes<hr>',
        html: "<ol class='list-group list-group-numbered'><li class='list-group-item d-flex justify-content-between align-items-start'><div class='ms-2 me-auto'><div class='fw-bold'>Nombres Completos</div></div></li><li class='list-group-item d-flex justify-content-between align-items-start'><div class='ms-2 me-auto'><div class='fw-bold'>No. Documento</div></div></li><li class='list-group-item d-flex justify-content-between align-items-start'><div class='ms-2 me-auto'><div class='fw-bold'>Correo Electrónico</div></div></li><li class='list-group-item d-flex justify-content-between align-items-start'><div class='ms-2 me-auto'><div class='fw-bold'>Indicativo de Rol</div>Estudiantes = 4<br>Docentes = 5<br>Administrativos = 6</div></li><li class='list-group-item d-flex justify-content-between align-items-start'><div class='ms-2 me-auto'><div class='fw-bold'>Indicativo de Facultad</div>Ingenieria = 1<br>Veterinaria = 2<br>Administración = 3<br>Administrativos = 4</div></li></ol>",
        input: 'file',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Subir <i class="bi bi-cloud-arrow-up"></i>',
        cancelButtonColor: '#dc3545',
        showCancelButton: true,
        showCloseButton: true,
        inputAttributes: {
          'accept': 'csv/*'
        }
      })
      if (file) {
        if (file.type == 'text/csv') {
          const formData = new FormData();
          formData.append("Adjunto", file)
          this._userService.import(formData).subscribe(
            response => {
              if (response.respuesta == 'OK') {
                this.getUsers();
                this.mostrarCarga="none";
                this.alert('success', 'Importación', 'Datos sincronizados correctamente.');
              } else {
                this.mostrarCarga="none";
                this.alert('warning', 'Opss...', 'Error al subir el documento.');
              }
            }, error => {
              this.mostrarCarga="none";
              this.alert('error', 'Opss...', 'Error al subir el documento.');
            }
          );
        } else {
          this.mostrarCarga="none";
          this.alert('error', 'Extensión Invalida', 'No se puede subir el documento.');
        }
      }else{
        this.mostrarCarga="none";
      }
    })();
  }
  // mensajes de alerta
  alert(icon: any, title: any, text: any) {
    Swal.fire({
      position: 'center',
      icon: icon,
      title: title,
      text: text,
      confirmButtonColor: '#0d6efd',
      showConfirmButton: false,
      timer: 1500
    });
  }
}
