<title> Votaciones | Resultados </title>
<!-- SPINNER -->
<div [ngStyle]="{'display':mostrarCarga}" class="modal" data-keyboard="false" tabindex="-1"
  aria-labelledby="exampleModalLabel">
  <div id="config-spinner">
    <div class="text-center">
      <div class="modal-dialog">
        <div class="spinner-border configSpinner" role="status">
          <span class="visually-hidden modal sr-only"></span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex">
  <!-- -------------- -->
  <!--    SLIDEBAR    -->
  <!-- -------------- -->
  <div style="position: absolute; z-index: 1;">
    <app-navbar></app-navbar>
  </div>
  <div style="z-index:0;" id="contenido" class="w-100">
    <header class="d-flex flex-wrap py-3 mb-2 bg-light posicion-nombreUsuario">
      <svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" fill="currentColor" class="bi bi-person-circle"
        viewBox="0 0 16 16" *ngIf="!identity.foto">
        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
        <path fill-rule="evenodd"
          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
      </svg>
      <div class="avatar_mask" *ngIf="identity.foto">
        <img src="{{identity.foto}}">
      </div>
      <span class="nombre-usuario">{{identity.name}}</span>
    </header>
    <div class="configTituloPricipal">{{page_title}}</div>
    <hr>
    <div class="container">
      <div class="row">
        <div id="tarjeton" class="col-md-12 mt-5" *ngFor="let tarj of data">
          <h3 class="text-center" id="tituloTarjeton">{{tarj.nombre_tarjeton}}</h3>
          <div id="card" class="row">
            <div class="col-md-4" id="planchas" *ngFor="let planchas of tarj.planchas"
              title="{{planchas.nombre_plancha}}">
              <h3 id="nombre_plancha" class="text-center mt-2">{{planchas.nombre_plancha}}</h3>
              <h3 id="voto" *ngIf="planchas.votos">VOTOS:
                <span class="badge badge-info">{{planchas.votos}}</span></h3>
              <h3 id="voto" *ngIf="!planchas.votos">VOTOS:  <span class="badge badge-info">0</span></h3>
              <label id="voto_nulo">VOTOS NULOS: <span class="badge badge-warning">{{planchas.votos_nulos}}</span></label>

            </div>
          </div>
          <h4 style="float: left"><label style="font-weight: 600">INICIO: </label> {{tarj.fecha_inicio | amLocale: 'es' | amDateFormat:'MMMM Do YYYY, h:mm:ss a'}}</h4>
          <h4 style="float: right"><label style="font-weight: 600">CIERRE: </label> {{tarj.fecha_cierre | amLocale: 'es' | amDateFormat:'MMMM Do YYYY, h:mm:ss a'}}</h4>
          <h3 style="font-weight: 600" class="text-center">TOTAL VOTOS: <span style="font-size: 18px"
              class="badge badge-success">{{tarj.votos}}</span></h3>
        </div>
      </div><br>
      <hr><br>
      <button class="btn btn-success" (click)="resumenVotacion()">Resumen Proceso Votación</button>
    </div>
    <!-- Footer -->
    <div class="position-sticky animacionSecundaria" style="top: 120vh;">
      <footer>
        <div class="container text-center p-3">
          <blockquote class="blockquote" style="font-size: 18px; color: black; font-weight: 400; margin-bottom: 0%;">
            Copyright © 2022 · <a href="/desarrolladores" style="text-decoration: none; color: black"
              target="_blank">Gestión TIC e Innovación Tecnológica</a> | <a href="https://www.corhuila.edu.co"
              style="text-decoration: none; color: black;" target="_blank">Corporación Universitaria del Huila -
              CORHUILA</a>
          </blockquote>
        </div>
      </footer>
    </div>
  </div>
</div>
