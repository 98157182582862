import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { UserService } from "./user.service";

//debemos importar este clase a la clase de rutas y al app.module para que nos pueda funcionar


@Injectable()
export class NoIdentityGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _userService: UserService,
  ) {
  }

  // metodo canActivete para identificar si el usuario esta identificado para que pueda acceder a las rutas
  canActivate() {
    let identity = this._userService.getIdentity();
    if (identity && identity.name) {
      this._router.navigate(['inicio']);
      return false;
    } else {
      return true;
    }

  }
}
