<title> Votaciones | Usuarios </title>
<!-- SPINNER -->
<div [ngStyle]="{'display':mostrarCarga}" class="modal" data-keyboard="false" tabindex="-1"
  aria-labelledby="exampleModalLabel">
  <div id="config-spinner">
    <div class="text-center">
      <div class="modal-dialog">
        <div class="spinner-border configSpinner" role="status">
          <span class="visually-hidden modal sr-only"></span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex">
  <!-- -------------- -->
  <!--    SLIDEBAR    -->
  <!-- -------------- -->
    <div style="position: absolute; z-index: 1;">
      <app-navbar></app-navbar>
    </div>
    <div style="z-index:0;" id="contenido" class="w-100">
      <header class="d-flex flex-wrap py-3 mb-2 bg-light posicion-nombreUsuario">
        <svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" fill="currentColor"
          class="bi bi-person-circle" viewBox="0 0 16 16" *ngIf="!identity.foto">
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
          <path fill-rule="evenodd"
            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
        </svg>
        <div class="avatar_mask" *ngIf="identity.foto">
          <img src="{{identity.foto}}">
        </div>
        <span class="nombre-usuario">{{identity.name}}</span>
      </header>
      <div class="configTituloPricipal">{{page_title}}</div>
      <hr>
      <div class="margen-btnCrear animacionSecundaria crear-user" type="button">
        <span class="texto-crearUsuario">Crear Usuario</span>
        <button class="btn btn-crearUsuario" [routerLink]="['/registro-usuario']">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-plus iconCrear" viewBox="0 0 16 16">
            <path
              d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
          </svg>
        </button>
      </div>
      <div>
        <div class="row">
          <div class="col-md-12">
            <div class="buscador">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Buscar..</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ingrese el dato a buscar..." #input>
              </mat-form-field>
            </div>
            <div class="table-responsive animacionSecundaria margen-table">
              <table mat-table [dataSource]="user" class="table table-striped">
                <!--Column -->
                <ng-container matColumnDef="#">
                  <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
                </ng-container>
                <!--Column -->
                <ng-container matColumnDef="No. Documento">
                  <mat-header-cell *matHeaderCellDef> No. Documento </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.cedula}} </mat-cell>
                </ng-container>
                <!--Column -->
                <ng-container matColumnDef="Nombre">
                  <mat-header-cell *matHeaderCellDef> Nombre </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <h4 style="font-weight: 800" *ngIf="element.id_rol=='1'">{{element.name}}</h4>
                    <h4 *ngIf="element.id_rol!='1'">{{element.name}}</h4>
                  </mat-cell>
                </ng-container>
                <!--Column -->
                <ng-container matColumnDef="Correo Electronico">
                  <mat-header-cell *matHeaderCellDef> Correo Electronico </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
                </ng-container>
                <!--Column -->
                <ng-container matColumnDef="Rol">
                  <mat-header-cell *matHeaderCellDef> Rol </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <h3>
                      <span class="badge badge-info" *ngIf="element.id_rol=='1'">ADMIN</span>
                    </h3>
                    <h3>
                      <span class="badge badge-primary" *ngIf="element.id_rol=='2'">USUARIO EXTERNO</span>
                    </h3>
                    <h3>
                      <span class="badge badge-success" *ngIf="element.id_rol=='4'">ESTUDIANTE</span>
                    </h3>
                    <h3>
                      <span class="badge badge-warning" *ngIf="element.id_rol=='5'">DOCENTE</span>
                    </h3>
                    <h3>
                      <span class="badge badge-light" *ngIf="element.id_rol=='6'">ADMINISTRATIVO</span>
                    </h3>
                  </mat-cell>
                </ng-container>
                <!--Column -->
                <ng-container matColumnDef="Candidato">
                  <mat-header-cell *matHeaderCellDef> Candidato </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <i id="checkTrue" *ngIf="element.candidato==true">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" fill="currentColor"
                        class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path
                          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                      </svg>
                    </i>
                    <i id="checkFalse" *ngIf="element.candidato==false || element.candidato==null">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" fill="currentColor"
                        class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                        <path
                          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                      </svg>
                    </i>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Acciones">
                  <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
                  <mat-cell *matCellDef="let users">

                    <button class="btn btn-secondary btn-actualizar" [routerLink]="['/editar-usuario/', users.id]">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" fill="currentColor"
                        class="btn-editDelete bi bi-pencil-square" viewBox="0 0 16 16">
                        <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                      </svg>

                    </button>
                    <button class="btn btn-danger btn-eliminar" [swal]="deleteSwal">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" fill="currentColor"
                        class="btn-editDelete bi bi-trash" viewBox="0 0 16 16">
                        <path
                          d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                        <path fill-rule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                      </svg>

                      <swal #deleteSwal title="¿Esta Seguro de Eliminar a {{ users.name }}?"
                        text="Esto no se puede deshacer" icon="question" [showCancelButton]="true" [focusCancel]="true"
                        (confirm)="deleteUser(users.id)">
                      </swal>
                    </button>

                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

              </table>
              <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of user"></mat-paginator>
            </div>
          </div>
        </div>
      </div>
      <div class="container" style="padding-left: 50px; padding-right: 50px;">
      </div>
      <!-- Footer -->
      <div class="position-sticky animacionSecundaria" style="top: 120vh;">
        <footer>
          <div class="container text-center p-3">
            <blockquote class="blockquote" style="font-size: 18px; color: black; font-weight: 400; margin-bottom: 0%;">
              Copyright © 2022 · <a href="/desarrolladores" style="text-decoration: none; color: black"
                target="_blank">Gestión TIC e Innovación Tecnológica</a> | <a href="https://www.corhuila.edu.co"
                style="text-decoration: none; color: black;" target="_blank">Corporación Universitaria del Huila -
                CORHUILA</a>
            </blockquote>
          </div>
        </footer>
      </div>
    </div>
  </div>
