<title> Votaciones | Inicio </title>
<!-- SPINNER -->
<div [ngStyle]="{'display':mostrarCarga}" class="modal" data-keyboard="false" tabindex="-1"
  aria-labelledby="exampleModalLabel">
  <div id="config-spinner">
    <div class="text-center">
      <div class="modal-dialog">
        <div class="spinner-border configSpinner" role="status">
          <span class="visually-hidden modal sr-only"></span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex">
  <!-- -------------- -->
  <!--    SLIDEBAR    -->
  <!-- -------------- -->
  <div style="position: absolute; z-index: 1;">
    <app-navbar></app-navbar>
  </div>
  <div style="z-index:0;" id="contenido" class="w-100 row">
    <header class="d-flex flex-wrap py-3 mb-2 bg-light posicion-nombreUsuario">
      <svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" fill="currentColor" class="bi bi-person-circle"
        viewBox="0 0 16 16" *ngIf="!identity.foto">
        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
        <path fill-rule="evenodd"
          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
      </svg>
      <div class="avatar_mask" *ngIf="identity.foto">
        <img src="{{identity.foto}}">
      </div>
      <span class="nombre-usuario">{{identity.name}}</span>
    </header>
    <div class="configTituloPricipal">{{page_title}}</div>
    <hr>
    <div class="margen-btnCrear animacionSecundaria crear-user" type="button">
      <span class="texto-crearUsuario">Crear Tarjetones</span>
      <button class="btn btn-crearUsuario" (click)="modalAbrirCrear()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-plus iconCrear" viewBox="0 0 16 16">
          <path
            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg>
      </button>
    </div>
    <div class="buscador">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Buscar..</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ingrese el dato a buscar..." #input>
      </mat-form-field>
    </div>
    <div class="alert alert-warning ml-4 mr-4 mt-3 text-center" role="alert" *ngIf="status=='error'">
      <h4 style="margin-top: 15px; color: black; font-weight: 700; font-size: 20px;">No tienes tarjetones creados :(</h4>
    </div>
    <div class="table-responsive animacionSecundaria margen-table col-md-12 ml-3" *ngIf="status=='success'">
      <table mat-table [dataSource]="tarj" class="table table-striped">
        <!--Column -->
        <ng-container matColumnDef="Tarjetón">
          <mat-header-cell *matHeaderCellDef> Tarjetón </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <h5 style="font-weight: 700">{{element.nombre_tarjeton}} </h5>
          </mat-cell>
        </ng-container>
        <!--Column -->
        <ng-container matColumnDef="Fecha Inicio">
          <mat-header-cell *matHeaderCellDef> Fecha Inicio </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.fecha_inicio | amLocale: 'es' | amDateFormat:'MMMM Do YYYY, h:mm:ss a'}}</mat-cell>
        </ng-container>
        <!--Column -->
        <ng-container matColumnDef="Fecha Cierre">
          <mat-header-cell *matHeaderCellDef> Fecha Cierre </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.fecha_cierre | amLocale: 'es' | amDateFormat:'MMMM Do YYYY, h:mm:ss a'}} </mat-cell>
        </ng-container>
        <!--Column -->
        <ng-container matColumnDef="Población Convocada">
          <mat-header-cell *matHeaderCellDef> Población Convocada </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <h3>
              <span class="badge badge-success" *ngIf="element.rol_name=='ESTUDIANTE'">ESTUDIANTE</span>
            </h3>
            <h3>
              <span class="badge badge-warning" *ngIf="element.rol_name=='DOCENTE'">DOCENTE</span>
            </h3>
            <h3>
              <span class="badge badge-primary" *ngIf="element.rol_name=='ADMINISTRATIVO'">ADMINISTRATIVO</span>
            </h3>
          </mat-cell>
        </ng-container>
        <!--Column -->
        <ng-container matColumnDef="Organo Elegido">
          <mat-header-cell *matHeaderCellDef> Órgano a Elegir </mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.dirigido_name}}
          </mat-cell>
        </ng-container>
        <!--Column -->
        <ng-container matColumnDef="Estado">
          <mat-header-cell *matHeaderCellDef> Estado </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <h3><span class="badge badge-success" *ngIf="element.id_estado=='1'">Activo</span></h3>
            <h3><span class="badge badge-warning" *ngIf="element.id_estado=='2'">Inactivo</span></h3>
          </mat-cell>
        </ng-container>
        <!--Column -->
        <ng-container matColumnDef="Acciones">
          <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
          <mat-cell *matCellDef="let tarjetones">
            <!-- Crear planchas a los tarjetones -->
            <button class="btn btn-info btn-plancha" title="Crear Plancha" (click)="modalCrearPlancha(tarjetones)">
              <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="currentColor"
                class="btn-editDelete bi bi-receipt" viewBox="0 0 16 16">
                <path
                  d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z" />
                <path
                  d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
              </svg>
            </button>
            <!-- Conovocar votantes-->
            <button class="btn btn-success btn-plancha" title="Convocar" (click)="convocar(tarjetones)">
              <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="currentColor"
                class="bi bi-send-plus-fill" viewBox="0 0 16 16">
                <path
                  d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 1.59 2.498C8 14 8 13 8 12.5a4.5 4.5 0 0 1 5.026-4.47L15.964.686Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                <path
                  d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 0 0 1 0v-1h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-.5-.5Z" />
              </svg>
            </button>
            <!-- Resultados -->
            <button class="btn btn-dark btn-plancha" title="Resultados" (click)="resultados(tarjetones.id)">
              <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
              </svg>
            </button>
            <!-- Actualizar Tarjeton -->
            <button class="btn btn-secondary btn-actualizar" (click)="getTarjeton(tarjetones.id)"
              title="Actualizar Tarjetón">
              <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="currentColor"
                class="btn-editDelete bi bi-pencil-square" viewBox="0 0 16 16">
                <path
                  d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
              </svg>
            </button>
            <!-- Eliminar Tarjeton -->
            <button class="btn btn-danger btn-eliminar" [swal]="deleteSwal" title="Eliminar Tarjetón">
              <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="currentColor"
                class="btn-editDelete bi bi-trash" viewBox="0 0 16 16">
                <path
                  d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                <path fill-rule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
              </svg>

              <swal #deleteSwal title="Eliminar Tarjetón {{ tarjetones.nombre_tarjeton }}?"
                text="Esto no se puede deshacer" icon="question" [showCancelButton]="true" [focusCancel]="true"
                (confirm)="deleteTarjeton(tarjetones.id)">
              </swal>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of user"></mat-paginator>

    </div>
    <!-- Modal Crear Tarjeton-->
    <div class="modal" id="{{'myModalTarjeton'}}" [ngStyle]="{'display':modalCrear}">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content configModalBorder">
          <!-- Título -->
          <div class="configTituloModal">Tarjetón</div><br>
          <hr>
          <div class="modal-body">
            <!-- Campos del MODAL -->
            <form class="container" #registerForm="ngForm">
              <div class="mb-4">
                <div class="formGroup field">
                  <input required="" placeholder="Digite el nombre del tarjetón" class="formField" type="text"
                    name="nombre_tarjeton" #nombre_tarjeton="ngModel" [(ngModel)]="tarjeton.nombre_tarjeton"
                    oninput="javascript:this.value=this.value.toUpperCase();">
                  <label class="formLabel">Nombre del tarjetón <b style="color: rgb(247, 89, 89);">*</b></label>
                </div>
              </div>
              <div class="mb-4">
                <div class="formGroup field">
                  <input required="" class="formField" type="datetime-local" name="fecha_inicio" #fecha_inicio="ngModel"
                    [(ngModel)]="tarjeton.fecha_inicio">
                    <label class="formLabel">Fecha de Inicio <b style="color: rgb(247, 89, 89);">*</b></label>
                </div>
              </div>
              <div class="mb-4">
                <div class="formGroup field">
                  <input required="" class="formField" type="datetime-local" name="fecha_cierre" #fecha_cierre="ngModel"
                    [(ngModel)]="tarjeton.fecha_cierre">
                    <label class="formLabel">Fecha de Cierre <b style="color: rgb(247, 89, 89);">*</b></label>
                </div>
              </div>
              <div class="mb-4">
                <div class="formGroup field">
                  <select class="form-control" name="id_rol" #id_rol="ngModel" [(ngModel)]="tarjeton.id_rol">
                    <option selected hidden value="null">Seleccione</option>
                    <option class="form__option" *ngFor="let rol of roles" value="{{rol.id}}">
                      {{rol.nombre}}
                    </option>
                  </select>
                  <label class="formLabel">Población a Convocar <b style="color: rgb(247, 89, 89);">*</b></label>
                </div>
              </div>
              <div class="mb-4">
                <div class="formGroup field">
                  <select class="form-control" name="id_dirigido" #id_dirigido="ngModel"
                    [(ngModel)]="tarjeton.id_dirigido">
                    <option selected hidden value="null">Seleccione</option>
                    <option class="form__option" *ngFor="let tipocargo of tipo_cargos" value="{{tipocargo.id}}">
                      {{tipocargo.cargo}}
                    </option>
                  </select>
                  <label class="formLabel">Organo a Elegir <b style="color: rgb(247, 89, 89);">*</b></label>
                </div>
              </div>
              <!-- Botones -->
              <div class="text-center">
                <button class="btn btn-primary btn-modal col-lg-4 col-sm-5" [disabled]="registerForm.invalid"
                  (click)="crearTarjeton(registerForm)" data-dismiss="modal">Crear</button>
                <button type="button" class="btn btn-danger btn-modal col-lg-4 col-sm-5" data-dismiss="modal"
                  (click)="cancelarCrear(registerForm)">Cancelar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Actualizar Tarjeton-->
    <div class="modal" id="{{'myModalUpdate'}}" [ngStyle]="{'display':modalActualizar}">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content configModalBorder">
          <!-- Título -->
          <div class="configTituloModal">Actualizar Tarjetón</div><br>
          <hr>
          <div class="modal-body">
            <!-- Campos del MODAL -->
            <form class="container" #updateForm="ngForm">

              <div class="mb-4">
                <div class="formGroup field">
                  <input required="" placeholder="Digite el nombre del tarjetón" class="formField" type="text"
                    name="nombre_tarjeton" #nombre_tarjeton="ngModel" [(ngModel)]="tarjetonId.nombre_tarjeton"
                    oninput="javascript:this.value=this.value.toUpperCase();">
                  <small *ngIf="!nombre_tarjeton.valid && nombre_tarjeton.touched" class="invalid-feedback d-block">El
                    nombre del tarjetón no Existe¡</small>
                  <label class="formLabel">Nombre del tarjetón <b style="color: rgb(247, 89, 89);">*</b></label>
                </div>
              </div>

              <div class="mb-4">
                <div class="formGroup field">
                  <input required="" class="formField" type="datetime-local" name="fecha_inicio" #fecha_inicio="ngModel"
                    [(ngModel)]="tarjetonId.fecha_inicio">
                  <small *ngIf="!fecha_inicio.valid && fecha_inicio.touched" class="invalid-feedback d-block">La fecha
                    inicial no Existe¡</small>
                  <label class="formLabel">Fecha de Inicio <b style="color: rgb(247, 89, 89);">*</b></label>
                </div>
              </div>

              <div class="mb-4">
                <div class="formGroup field">
                  <input required="" class="formField" type="datetime-local" name="fecha_cierre" #fecha_cierre="ngModel"
                    [(ngModel)]="tarjetonId.fecha_cierre">
                  <small *ngIf="!fecha_cierre.valid && fecha_cierre.touched" class="invalid-feedback d-block">La fecha
                    de cierre no Existe¡</small>
                  <label class="formLabel">Fecha de Cierre <b style="color: rgb(247, 89, 89);">*</b></label>
                </div>
              </div>
              <div class="mb-4">
                <div class="formGroup field">
                  <select class="form-control mt-2" name="id_rol" #id_rol="ngModel" [(ngModel)]="tarjetonId.id_rol">
                    <option selected hidden value="null">Seleccione</option>
                    <option class="form__option" *ngFor="let rol of roles" value="{{rol.id}}">
                      {{rol.nombre}}
                    </option>
                  </select>
                  <label class="formLabel">Población a Convocar <b style="color: rgb(247, 89, 89);">*</b></label>
                </div>
              </div>
              <div class="mb-4">
                <div class="formGroup field">
                  <select class="form-control mt-2" name="id_dirigido" #id_dirigido="ngModel"
                    [(ngModel)]="tarjetonId.id_dirigido">
                    <option selected hidden value="null">Seleccione</option>
                    <option class="form__option" *ngFor="let tipocargo of tipo_cargos" value="{{tipocargo.id}}">
                      {{tipocargo.cargo}}
                    </option>
                  </select>
                  <label class="formLabel">Organo a Elegir <b style="color: rgb(247, 89, 89);">*</b></label>
                </div>
              </div>
              <div class="mb-4">
                <div class="formGroup field">
                  <select class="form-control mt-2" name="id_estado" #id_estado="ngModel"
                    [(ngModel)]="tarjetonId.id_estado">
                    <option class="form__option" *ngFor="let estado of estados" value="{{estado.id}}">
                      {{ estado.nombre_estado }}
                    </option>
                  </select>
                  <label class="formLabel">Estado <b style="color: rgb(247, 89, 89);">*</b></label>
                </div>
              </div>
              <!-- Botones -->
              <div class="text-center">
                <button class="btn btn-primary btn-modal col-lg-4 col-sm-5" [swal]="updateSwalTarjeton">Guardar</button>
                <swal #updateSwalTarjeton title="Estas seguro de Actualizar" text="Esto no se puede deshacer"
                  icon="warning" [showCancelButton]="true" confirmButtonColor="#0d6efd" [focusCancel]="true"
                  (confirm)="Update(updateForm)">
                </swal>
                <button type="button" class="btn btn-danger btn-modal col-lg-4 col-sm-5" data-dismiss="modal"
                  (click)="modalCancelar()">Cancelar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Crear Plancha-->
    <div class="modal" id="{{'myModalPlancha'}}" [ngStyle]="{'display':modalPlancha}">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content configModalBorder">
          <!-- Título -->
          <div class="configTituloModal">PLANCHA</div>
          <div class="modal-body">
            <!-- Campos del MODAL -->
            <form class="container" #formPlancha="ngForm">
              <div class="mb-4">
                <div class="formGroup field">
                  <label class="formLabel">Tarjetón Seleccionado</label>
                  <input type="number" class="form-control" #id_tarjeton="ngModel" name="id_tarjeton"
                    [(ngModel)]="plancha.id_tarjeton" value="{{dataTarjeton.id}}" style="display: none">
                  <h1 class="mt-2"><span class="badge badge-secondary">{{dataTarjeton.nombre_tarjeton}}</span></h1>
                </div>
              </div>
              <div class="mb-4">
                <div class="formGroup field">
                  <label class="formLabel">Nombre de la Plancha <b style="color: rgb(247, 89, 89);">*</b></label>
                  <input required="" placeholder="Digite el nombre de la plancha" class="form-control mt-2" type="text"
                    name="nombre_plancha" #nombre_plancha="ngModel" [(ngModel)]="plancha.nombre_plancha"
                    oninput="javascript:this.value=this.value.toUpperCase();">
                </div>
              </div>
              <div class="mb-4">
                <div class="formGroup field">
                  <label class="formLabel">Candidato Principal <b style="color: rgb(247, 89, 89);">*</b></label>
                  <select class="form-control  mt-2" name="id_principal" #id_principal="ngModel"
                    [(ngModel)]="plancha.id_principal" (change)="changeCandidato($event)">
                    <option selected hidden value="null">Selecciona</option>
                    <option class="form__option" *ngFor="let user of users" value="{{user.id}}">
                      {{user.name}} {{user.surname}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="mb-4">
                <div class="formGroup field">
                  <label class="formLabel">Candidato Suplente <b style="color: rgb(247, 89, 89);">*</b></label>
                  <select class="form-control  mt-2" name="id_secundario" #id_secundario="ngModel"
                    [(ngModel)]="plancha.id_secundario">
                    <option selected hidden value="null">Selecciona</option>
                    <option class="form__option" *ngFor="let user of users_aux" value="{{user.id}}">
                      {{user.name}} {{user.surname}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="text-center">
                <button class="btn btn-primary btn-modal col-lg-4 col-sm-5" (click)="crearPlancha(formPlancha)"
                  [disabled]="formPlancha.invalid">Crear</button>
                <button type="button" class="btn btn-danger btn-modal col-lg-4 col-sm-5"
                  (click)="cancelarModalPlancha(formPlancha)">Cancelar</button>
              </div>
              <hr>
              <div class="buscadorPlancha">
                <mat-form-field class="example-full-width-plancha" appearance="outline">
                  <mat-label>Buscar..</mat-label>
                  <input matInput (keyup)="applyFilterPlancha($event)" placeholder="Ingrese el dato a buscar..." #input>
                </mat-form-field>
              </div>
              <div class="table-responsive animacionSecundaria margen-tablePlancha">
                <!-- Tabla de Planchas -->
                <table mat-table [dataSource]="listPlancha" class="table table-striped">
                  <!--Column -->
                  <ng-container matColumnDef="Plancha">
                    <mat-header-cell *matHeaderCellDef> Plancha </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.nombre_plancha}} </mat-cell>
                  </ng-container>
                  <!--Column -->
                  <ng-container matColumnDef="Candidato P">
                    <mat-header-cell *matHeaderCellDef> Candidato P </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.id_principal}} </mat-cell>
                  </ng-container>
                  <!--Column -->
                  <ng-container matColumnDef="Candidato S">
                    <mat-header-cell *matHeaderCellDef> Candidato S </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.id_secundario}} </mat-cell>
                  </ng-container>
                  <!--Column -->
                  <ng-container matColumnDef="Estado">
                    <mat-header-cell *matHeaderCellDef> Estado </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <h3><span class="badge badge-success" *ngIf="element.id_estado=='1'">Activo</span></h3>
                      <h3><span class="badge badge-success" *ngIf="element.id_estado!='1'">Inactivo</span></h3>
                    </mat-cell>
                  </ng-container>
                  <!--Column -->
                  <ng-container matColumnDef="Acciones">
                    <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
                    <mat-cell *matCellDef="let planchas">
                      <button class="btn btn-secondary btn-actualizar" title="Actualizar"
                        (click)="getPlanchaId(planchas.id)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" fill="currentColor"
                          class="btn-editDelete bi bi-pencil-square" viewBox="0 0 16 16">
                          <path
                            d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                        </svg>
                      </button>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="displayedColumnsPlancha"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumnsPlancha;"></mat-row>
                </table>
              </div>
            </form>
            <!--Modal Actualizar Plancha-->
            <div class="modal" [ngStyle]="{'display':modalActualizarPlancha}">
              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content configModalBorder">
                  <!-- Título -->
                  <div class="configTituloModal">Actualizar Plancha</div><br>
                  <hr>
                  <div class="modal-body">
                    <!-- Campos del MODAL -->
                    <form class="container" #updateFormPlancha="ngForm">
                      <!--Columna Nombre Tarjeton-->
                      <div class="mb-4">
                        <div class="formGroup field">
                          <label class="formLabel">Nombre de la Plancha</label>
                          <input required="" class="formField" type="text" name="nombre_plancha"
                            #nombre_plancha="ngModel" [(ngModel)]="planchaId.nombre_plancha"
                            oninput="javascript:this.value=this.value.toUpperCase();">
                        </div>
                      </div>
                      <div class="mb-4">
                        <div class="formGroup field">
                          <label class="formLabel">Candidato Principal</label>
                          <select class="form-control mt-2" name="id_principal" #id_principal="ngModel"
                            [(ngModel)]="planchaId.id_principal" (change)="changeCandidato($event)">
                            <option class="form__option" *ngFor="let user of users" value="{{user.id}}">
                              {{user.name}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <!--Columna Candidato Suplente-->
                      <div class="mb-4">
                        <div class="formGroup field">
                          <label class="formLabel">Candidato Suplente</label>
                          <select class="form-control mt-2" name="id_secundario" #id_secundario="ngModel"
                            [(ngModel)]="planchaId.id_secundario">
                            <option class="form__option" *ngFor="let user of users_aux" value="{{user.id}}">
                              {{user.name}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <!-- Botones -->
                      <div class="text-center">
                        <button class="btn btn-primary btn-modal col-lg-4 col-sm-5" [swal]="updateSwal">Guardar</button>
                        <swal #updateSwal title="Estas seguro de actualizar" text="Esto no se puede deshacer"
                          icon="warning" [showCancelButton]="true" confirmButtonColor="#0d6efd" [focusCancel]="true"
                          (confirm)="updatePlancha(updateFormPlancha)">
                        </swal>
                        <button type="button" class="btn btn-danger btn-modal col-lg-4 col-sm-5" data-dismiss="modal"
                          (click)="cancelarModalUpdatePlancha()">Cancelar</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="padding-left: 50px; padding-right: 50px;">
    </div>
    <!-- Footer -->
    <div class="position-sticky animacionSecundaria" style="top: 120vh;">
      <footer>
        <div class="container text-center p-3">
          <blockquote class="blockquote" style="font-size: 18px; color: black; font-weight: 400; margin-bottom: 0%;">
            Copyright © 2022 · <a href="/desarrolladores" style="text-decoration: none; color: black"
              target="_blank">Gestión TIC e Innovación Tecnológica</a> | <a href="https://www.corhuila.edu.co"
              style="text-decoration: none; color: black;" target="_blank">Corporación Universitaria del Huila -
              CORHUILA</a>
          </blockquote>
        </div>
      </footer>
    </div>
  </div>
</div>
