import { Component, DoCheck, OnInit } from '@angular/core';
import { UserService } from './service/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [ UserService]
})
export class AppComponent implements OnInit, DoCheck {
  public title = 'Votaciones CORHUILA';
  public identity: any;
  public token: any;

  constructor(private _userService: UserService){
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
  }
  ngDoCheck(): void {
    this.identity = this._userService.getIdentity();
  }
  ngOnInit() {
  }

}
