<title> Votaciones | Inicio </title>

<!-- -------------- -->
<!--    SLIDEBAR    -->
<!-- -------------- -->
<div class="d-flex">
  <div style="position: absolute; z-index: 1;">
    <app-navbar></app-navbar>
  </div>
  <div style="z-index:0;" id="contenido" class="w-100">
    <div class="configTituloPricipal" style="margin-top: 100px;">{{page_title}}</div>
    <hr>
    <div class="container" style="align-content: center;">
      <div class="centrar">
        <img class="vote"
          src="./assets/img/bienvenidad2.png">
      </div>
    </div>
    <!-- Footer -->
    <div class="position-sticky animacionSecundaria" style="top: 120vh;">
      <footer>
        <div class="container text-center p-3">
          <blockquote class="blockquote" style="font-size: 18px; color: black; font-weight: 400; margin-bottom: 0%;">
            Copyright © 2022 · <a href="/desarrolladores" style="text-decoration: none; color: black"
              target="_blank">Gestión TIC e Innovación Tecnológica</a> | <a href="https://www.corhuila.edu.co"
              style="text-decoration: none; color: black;" target="_blank">Corporación Universitaria del Huila -
              CORHUILA</a>
          </blockquote>
        </div>
      </footer>
    </div>
  </div>
</div>
